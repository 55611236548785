<template>
  <v-navigation-drawer
    v-model="display"
    app
    style="height: 100%"
    dark
    @input="emitMenuStatus()"
  >
    <template #prepend>
      <v-container class="pa-0">
        <v-card tile dark elevation="0" @click="$emit('go-to', 'Account')">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline">
                {{ auth.user_name }}
              </div>
              <v-list-item-title>
                <span class="text-caption">
                  {{ auth.first_name }} {{ auth.last_name }}</span
                ><br />
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text-caption">{{ auth.email }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar size="48">
              <v-img :src="auth.avatar_img_url" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-container>
    </template>

    <v-list dense class="py-0">
      <v-subheader>
        {{ $t("navigation.menu.title") }}
      </v-subheader>

      <v-list-item
        v-if="['superadmin', 'admin', 'editor', 'manager'].includes(role)"
        link
        :to="{ name: 'Dashboard' }"
      >
        <v-list-item-icon>
          <v-icon small>mdi-apps</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("navigation.dashboard.title") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="['superadmin'].includes(role)"
        link
        :to="{ name: 'StructureAdministration' }"
      >
        <v-list-item-icon>
          <v-icon small> mdi-lock </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Administration</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="['superadmin', 'admin', 'manager'].includes(role) && showStats"
        link
        :to="{ name: 'Statistics' }"
      >
        <v-list-item-icon>
          <v-icon small>mdi-finance</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("navigation.statistics.title") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="['superadmin', 'admin'].includes(role) && showKPI"
        link
        :to="{ name: 'Kpi' }"
      >
        <v-list-item-icon>
          <v-icon small>mdi-chart-areaspline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("kpi.label") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="['superadmin', 'admin'].includes(role)"
        link
        :to="{ name: 'SupportOverview' }"
      >
        <v-list-item-icon>
          <v-icon small>mdi-face-agent</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("resources.support.support") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1" />

      <v-subheader v-if="['superadmin', 'admin', 'manager'].includes(role)">
        {{ $t("navigation.management.title") }}
      </v-subheader>

      <v-list-item
        v-if="['superadmin', 'admin', 'manager'].includes(role)"
        link
        :to="{ name: 'UserOverview' }"
      >
        <v-list-item-icon>
          <v-icon small> mdi-account-multiple </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("navigation.users.title") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="
          ['superadmin', 'admin', 'manager'].includes(role) &&
          !hideRegisterOptions
        "
        link
        :to="{ name: 'InvitationOverview' }"
      >
        <v-list-item-icon>
          <v-icon small> mdi-account-multiple-plus </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("navigation.invitations.title") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="
          ['superadmin', 'admin', 'manager'].includes(role) &&
          !hideRegisterOptions
        "
        link
        :to="{ name: 'CodeOverview' }"
      >
        <v-list-item-icon>
          <v-icon small> mdi-numeric </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("navigation.codes.title") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider
        v-if="['superadmin', 'admin', 'editor'].includes(role)"
        class="my-1"
      />

      <v-subheader v-if="['superadmin', 'admin', 'editor'].includes(role)">
        {{ $t("navigation.content.title") }}
      </v-subheader>

      <v-list-item
        v-if="['superadmin', 'admin', 'editor'].includes(role)"
        link
        :to="{ name: 'CategoryOverview' }"
      >
        <v-list-item-icon>
          <v-icon small> mdi-shape </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("resources.categories.plural") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="['superadmin', 'admin', 'editor'].includes(role)"
        link
        :to="{ name: 'QuestionOverview' }"
      >
        <v-list-item-icon>
          <v-icon small> mdi-comment-question </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("resources.questions.plural") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="['superadmin', 'admin', 'editor'].includes(role)"
        link
        :to="{ name: 'NuggetOverview' }"
      >
        <v-list-item-icon>
          <v-icon small> mdi-school </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("resources.nuggets.plural") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="['superadmin', 'admin', 'manager', 'editor'].includes(role)"
        link
        :to="{ name: 'Journeys' }"
        router-link-active
      >
        <v-list-item-icon>
          <v-icon small>mdi-map-marker-path</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("resources.journeys.journey.plural") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="
          ['superadmin', 'admin', 'editor'].includes(role) && showGhostwriter
        "
        link
        :to="{ name: 'Ghostwriter' }"
      >
        <v-list-item-icon>
          <v-icon small>mdi-typewriter</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("gw.ghostwriter") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template #append>
      <v-divider />
      <v-row no-gutters>
        <v-col v-if="['superadmin', 'admin'].includes(role)" cols="3">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn depressed block tile :to="{ name: 'Community' }">
                  <v-icon>mdi-earth</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("navigation.community.title") }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="3">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn depressed tile :to="{ name: 'Account' }">
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("navigation.account.title") }}</span>
          </v-tooltip>
        </v-col>
        <v-col :cols="!['superadmin', 'admin'].includes(role) ? 6 : 3">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn depressed block tile :href="appLink" target="_blank">
                  <v-icon>mdi-play</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("ng.to_app") }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="3">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn depressed block tile @click="$emit('logout')">
                  <v-icon color="error">mdi-logout</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("navigation.logout.title") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppSideBar",
  props: {
    menu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      display: false,
    };
  },
  computed: {
    ...mapGetters("auth", { auth_sources: "get_auth_sources" }),
    showStats() {
      if (
        process.env.VUE_APP_HIDE_STATS_FOR_MANAGER === "true" &&
        this.role == "manager"
      )
        return false;
      return true;
    },
    showGhostwriter() {
      return process.env.VUE_APP_GHOSTWRITER === "true";
    },
    showKPI() {
      return process.env.VUE_APP_SHOW_KPI === "true";
    },
    appLink() {
      return `https://${process.env.VUE_APP_INSTANCE_NAME}.p3l.app`;
    },
    hideRegisterOptions() {
      if (!this.auth_sources) return false;
      return !this.auth_sources.filter((s) => s.name === "local")[0];
    },
  },
  watch: {
    menu: function (value) {
      this.display = value;
    },
  },
  methods: {
    emitMenuStatus() {
      this.$emit("menu-status", this.display);
    },
  },
};
</script>

<style></style>
