<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="!loading" class="mt-4">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              solo
              label="Suche"
              append-icon="mdi-magnify"
              clearable
            />
          </v-col>
          <v-col cols="12">
            <v-card color="grey lighten-3" flat>
              <v-card-title>Source preview</v-card-title>
              <v-card-text>
                <div class="d-flex">
                  <v-autocomplete
                    v-model="currentSource"
                    :items="sortedSources"
                    return-object
                    solo
                    flat
                    background-color="primary"
                    dark
                    label="Sources preview"
                    clearable
                    hide-details
                    dense
                    active-class="white"
                  >
                    <template #item="{ item }">
                      [{{ item.id }}] - {{ item.title }} [{{ item.mimetype }}]
                    </template>
                    <template #selection="{ item }">
                      [{{ item.id }}] - {{ item.title }} [{{ item.mimetype }}]
                    </template>
                  </v-autocomplete>

                  <FileSourceDialog
                    :source-id="currentSource ? currentSource.id : ''"
                  >
                    <template #action="{ openDialog }">
                      <v-btn
                        class="ml-2"
                        color="primary"
                        :disabled="loading || !currentSource"
                        @click="openDialog()"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                  </FileSourceDialog>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card outlined>
              <v-list class="pb-0">
                <v-list-item v-if="!files || files.length === 0"
                  >No files.</v-list-item
                >
                <v-list-item
                  v-for="(file, index) in currentfiles"
                  :key="`file-${file.id}`"
                  :style="
                    index < currentfiles.length - 1
                      ? 'border-bottom: 1px solid #ccc'
                      : ''
                  "
                >
                  <!-- <v-list-item-icon>
                    <v-icon v-if="!file.job.id">mdi-check</v-icon>
                    <v-progress-circular
                      v-if="!!file.job.id"
                      indeterminate
                      size="20"
                      width="2"
                      color="primary"
                    />
                  </v-list-item-icon> -->
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ file.name }}
                      <span class="caption"
                        >(id: {{ file.id }})</span
                      ></v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <span class="caption">mimetype </span>
                      <span class="font-weight-bold">{{ file.mimetype }}</span>
                      <span class="caption ml-2">variants </span>
                      <span class="font-weight-bold">{{
                        file.variants.length
                      }}</span>
                      <span class="caption ml-2">user_id </span>
                      <span class="font-weight-bold">{{ file.user_id }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <FileDialog :file-id="file.id">
                      <template #action="{ openDialog }">
                        <v-btn
                          color="primary"
                          icon
                          dark
                          small
                          @click="openDialog()"
                        >
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                    </FileDialog>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-end">
            <v-btn
              small
              icon
              class="mr-4"
              :disabled="page == 1"
              @click="page--"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div>
              <v-btn
                v-for="i in Math.ceil(sortedfiles.length / itemsPerPage)"
                :key="`btn-${i}`"
                icon
                @click="page = i"
                >{{ i }}</v-btn
              >
            </div>
            <v-btn
              small
              icon
              class="ml-4"
              :disabled="page == Math.ceil(sortedfiles.length / itemsPerPage)"
              @click="page++"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader";
import FileDialog from "@/components/global/FileDialog";
import FileSourceDialog from "@/components/files/FileSourceDialog";

export default {
  name: "FileOverview",
  components: { ViewLoader, FileDialog, FileSourceDialog },
  data() {
    return {
      loading: true,
      search: "",
      itemsPerPage: 10,
      page: 1,
      currentSource: null,
    };
  },
  computed: {
    ...mapGetters("tmp", { files: "get_files" }),
    ...mapGetters("ghostwriter", { sources: "get_sources" }),
    sortedfiles() {
      if (!this.files) return [];
      /* var files = [...this.files].sort((a, b) => b.id - a.id); */
      var files = [...this.files].reverse();
      if (this.search) {
        files = files.filter((s) => {
          if (!s.path) return false;
          return s.path.toLowerCase().includes(this.search);
        });
      }
      return files;
    },
    currentfiles() {
      if (!this.files) return [];
      return this.sortedfiles.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage
      );
    },
    sortedSources() {
      if (!this.sources) return [];
      var sources = [...this.sources];
      return sources
        .sort((a, b) => b.id - a.id)
        .filter((s) => s.mimetype === "file");
    },
  },
  destroyed() {
    this.set_files([]);
    this.set_sources([]);
  },
  methods: {
    ...mapActions("ghostwriter", ["fetch_sources"]),
    ...mapActions("tmp", ["fetch_files"]),
    ...mapMutations("tmp", ["set_files"]),
    ...mapMutations("ghostwriter", ["set_sources"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_files();
      await this.fetch_sources();
      this.loading = false;
    },
  },
};
</script>
