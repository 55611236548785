<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      max-width="800"
      scrollable
    >
      <v-card color="grey lighten-3">
        <v-card-title class="overline secondary--text">{{
          $t("gw.presentation.slide_preview")
        }}</v-card-title>
        <v-card-subtitle
          >{{
            $t("gw.presentation.slide_preview_subtitle", {
              number: slide.number,
              count: slideCount,
            })
          }}
        </v-card-subtitle>
        <v-card-text>
          <v-img :src="slide.img" contain max-height="600">
            <div class="d-flex justify-space-between pa-2">
              <v-chip color="secondary" small class="pr-2 elevation-4">
                <span>{{ slide.number }}</span>
                <v-avatar
                  right
                  :color="selected ? 'success' : 'error'"
                  size="12"
                  style="margin-right: -8px"
                >
                  <v-icon x-small>{{
                    selected ? "mdi-check" : "mdi-close"
                  }}</v-icon>
                </v-avatar>
              </v-chip>
            </div>
          </v-img>
        </v-card-text>

        <v-card-actions>
          <v-btn small text @click="closeDialog()">{{
            $t("general.close")
          }}</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "SlidePreviewDialog",
  props: {
    slide: {
      type: Object,
      required: false,
    },
    slideCount: {
      type: [String, Number],
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
