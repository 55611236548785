<template>
  <div>
    <v-btn small :text="textButton" color="primary" @click="showDialog = true">
      <v-icon v-if="!textButton" small class="mr-2"> mdi-download </v-icon>
      <span>{{ $t("resources.export.export_data") }}</span>
    </v-btn>

    <v-dialog
      v-model="showDialog"
      max-width="480"
      @keydown.esc="closeDialog"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title class="overline primary--text justify-center">
          {{ $t("resources.export.export_data") }}
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <h5 class="secondary--text">
                {{ $t("resources.export.choose_filetype") }}
              </h5>
              <v-select
                v-model="selectedFiletype"
                :items="filetypes"
                item-text="name"
                item-value="id"
                dense
                filled
                :menu-props="{ bottom: true, offsetY: true }"
              >
                <template #item="{ item }"> .{{ item.name }} </template>
                <template #selection="{ item }"> .{{ item.name }} </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <h5 class="secondary--text">
                {{ $t("resources.export.filename") }}
              </h5>
              <v-text-field v-model="filename" filled dense>
                <template #append-outer>
                  <span class="overline"
                    >.{{ filetypes[selectedFiletype - 1].name }}</span
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small text @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
          <v-btn small text color="success" @click="exportData()">
            {{ $t("resources.export.export") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ExportDataDialog",
  props: {
    type: {
      type: String,
      required: true,
    },
    journeyId: {
      type: [String, Number],
      required: false,
    },
    textButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      showDialog: false,
      filetypes: [
        { id: 1, name: "csv" },
        { id: 2, name: "xlsx" },
        { id: 3, name: "json" },
      ],
      selectedFiletype: 2,
      filename: "",
    };
  },
  methods: {
    ...mapActions("journeys", ["export_journey_stats"]),
    ...mapActions("users", ["export_users"]),
    closeDialog() {
      this.showDialog = false;
    },
    async exportData() {
      let payload = {
        format: this.filetypes.filter(
          (filetype) => filetype.id === this.selectedFiletype
        )[0].name,
      };
      if (this.journeyId) payload.journey_id = this.journeyId;
      let config = {
        responseType: "blob",
      };
      let res =
        this.type === "users"
          ? await this.export_users({ payload, config })
          : await this.export_journey_stats({ payload, config });
      let contentType = res._headers["content-type"];
      let blob = new Blob([res], { type: contentType });
      let filename = this.filename
        ? this.filename
        : res._headers["content-disposition"]
            .split(";")[1]
            .trim()
            .replace("filename=", "");
      var link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
  },
};
</script>

<style></style>
