<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog v-if="dialog" v-model="dialog" persistent max-width="800">
      <v-card color="grey lighten-3" :loading="loading">
        <v-card-title>File</v-card-title>
        <v-card-text v-if="file">
          <v-subheader class="px-0">Info</v-subheader>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                :value="file.id"
                label="id"
                outlined
                dense
                hide-details
                background-color="white"
                disabled
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :value="file.name"
                label="name"
                outlined
                dense
                hide-details
                background-color="white"
                disabled
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :value="file.mimetype"
                label="mimetype"
                outlined
                dense
                hide-details
                background-color="white"
                disabled
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :value="file.client_id"
                label="client_id"
                outlined
                dense
                hide-details
                background-color="white"
                disabled
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="page"
                :label="`download which page? (total: ${file.pages})`"
                outlined
                dense
                type="number"
                min="1"
                :max="file.pages"
                hide-details
                background-color="white"
              />
            </v-col>
          </v-row>
          <v-subheader class="px-0">Variants</v-subheader>
          <v-list color="transparent" class="py-0">
            <v-list-item
              v-for="(variant, idx) in file.variants"
              :key="`var-${variant.id}-${idx}`"
              class="mb-2"
              :dark="idx === 0"
              :class="idx === 0 ? 'primary' : 'grey lighten-2'"
            >
              <v-list-item-icon>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">{{
                      getVariantIcon(variant)
                    }}</v-icon>
                  </template>
                  <span>{{ variant.variant }}</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ file.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  :disabled="loading"
                  @click="downloadFile(variant.variant)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn small text @click="closeDialog()">{{
            $t("general.close")
          }}</v-btn>
          <v-spacer />
          <v-btn
            small
            text
            color="primary"
            :disabled="loading"
            @click="createSource()"
            >Create source</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "FileDialog",
  props: {
    fileId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      page: 1,
    };
  },
  computed: {
    ...mapGetters("tmp", { file: "get_file" }),
  },
  methods: {
    ...mapActions("tmp", [
      "fetch_file",
      "fetch_download_token",
      "download_file",
      "create_source_from_file",
    ]),
    ...mapActions("ghostwriter", ["fetch_sources"]),
    ...mapMutations("tmp", ["set_file"]),
    openDialog() {
      this.dialog = true;
      this.fetchFile();
    },
    closeDialog() {
      this.dialog = false;
      this.set_file(null);
      this.page = 1;
    },
    async fetchFile() {
      this.loading = true;
      await this.fetch_file({ id: this.fileId });
      this.loading = false;
    },
    getVariantIcon(variant) {
      if (variant.variant === "pdf") return "mdi-file-pdf-box";
      if (variant.variant === "txt") return "mdi-text-box";
      if (variant.variant === "thumbnail") return "mdi-image";
      return "mdi-help-circle";
    },
    async downloadFile(variant) {
      this.loading = true;
      var res_token = await this.fetch_download_token({
        id: this.file.id,
        variant: !variant ? "pdf" : variant, // TODO: General file type check, adjust variant.variant & file.mimetype?!
      });
      if (!res_token || res_token._status !== 200) {
        this.$notify({ type: "error", title: this.$t("general.error") });
        return false;
      }
      var res_dl = await this.download_file({
        token: res_token.token,
        page: this.page,
        config: {
          responseType: "blob",
        },
      });

      var contentType = res_dl._headers["content-type"];
      var blob = new Blob([res_dl], { type: contentType });
      var filename = res_dl._headers["content-disposition"]
        .split(";")[1]
        .trim()
        .replace("filename=", "");
      var link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      this.loading = false;
    },
    async createSource() {
      var payload = {
        title: this.file.name,
        lang: "de-DE",
        file_id: this.file.id,
      };
      var res = await this.create_source_from_file({ payload: payload });

      if (res && res._status === 200) await this.fetch_sources();

      this.$notify({
        type: res && res._status === 200 ? "success" : "error",
        title:
          res && res._status === 200
            ? this.$t("general.success")
            : this.$t("general.error"),
      });
    },
  },
};
</script>
