<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.invitations.plural')"
      icon="mdi-account-multiple-plus"
      route-name="InvitationAdd"
    />
    <ViewLoader v-if="!invitations || !structures" />

    <section v-if="invitations && structures">
      <SectionHeader :title="`${$t('general.overview')}`" />

      <v-container class="py-0">
        <v-row>
          <v-col cols="12">
            <v-card outlined :loading="loading">
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn icon @click="showFilters = !showFilters">
                  <v-icon>{{
                    showFilters ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="showFilters">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="filter.status"
                      :items="statusArr"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.invitations.status.general')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template #selection="{ item }">
                        {{ $t("resources.invitations.status." + item) }}
                      </template>
                      <template #item="{ item }">
                        {{ $t("resources.invitations.status." + item) }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col v-if="substructures" cols="12">
                    <v-autocomplete
                      v-model="filter.substructures"
                      :items="visibleSubstructures"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.codes.assignable_substructures')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ substructureById(item.id).structure.name }} /
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="substructures" cols="12">
                    <v-autocomplete
                      v-model="filter.structures"
                      :items="visibleStructures"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.codes.selectable_structures')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      class="mt-0 pt-0"
                      prepend-icon="mdi-magnify"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      :label="$t('general.search')"
                      @click:append="filter.search = ''"
                      @keydown="filter.page = 1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="[
                  { text: $t('resources.invitations.email'), value: 'email' },
                  {
                    text: $t('resources.invitations.status.general'),
                    value: 'status',
                  },
                  {
                    text: $t('resources.codes.assignable_substructures'),
                    value: 'substructures',
                  },
                  {
                    text: $t('resources.codes.selectable_structures'),
                    value: 'structures',
                  },
                ]"
                :items="invitationsFiltered ? invitationsFiltered : []"
                :items-per-page="10"
                :page.sync="filter.page"
                :loading="!invitations || !structures"
              >
                <template #item="invitation">
                  <tr @click="showInvitation(invitation.item)">
                    <td>{{ invitation.item.email }}</td>
                    <td>
                      <v-chip x-small :color="getStatusColor(invitation.item)">
                        {{
                          $t(
                            "resources.invitations.status." +
                              invitation.item.status
                          )
                        }}
                      </v-chip>
                    </td>
                    <!-- <td>{{ invitation.item.created_at.toLocaleDateString($i18n.locale, { day: 'numeric', year: 'numeric', month: 'long' }) }}</td>
                <td>{{ invitation.item.expired_at.toLocaleDateString($i18n.locale, { day: 'numeric', year: 'numeric', month: 'long' }) }}</td> -->
                    <td>
                      <div
                        v-for="(substructure, i) in invitation.item
                          .assigned_substructure_ids"
                        :key="i"
                      >
                        <v-chip v-if="i < 2" x-small class="mx-1 my-1">
                          {{ substructureById(substructure).structure.name }} /
                          {{ substructureById(substructure).name }}
                        </v-chip>
                        <v-chip
                          v-if="i == 2"
                          x-small
                          class="mx-1 my-1"
                          disabled
                        >
                          +
                          {{
                            invitation.item.assigned_substructure_ids.length - 2
                          }}
                          {{ $t("resources.invitations.moreIndicator") }}
                        </v-chip>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(structure, i) in invitation.item
                          .select_structure_ids"
                        :key="i"
                      >
                        <v-chip
                          v-if="i < 2"
                          x-small
                          color="secondary"
                          class="mx-1 my-1"
                        >
                          {{ structureById(structure).name }}
                        </v-chip>
                        <v-chip
                          v-if="i == 2"
                          x-small
                          class="mx-1 my-1"
                          disabled
                        >
                          +
                          {{ invitation.item.select_structure_ids.length - 2 }}
                          {{ $t("resources.invitations.moreIndicator") }}
                        </v-chip>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "InvitationOverview",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
  },
  data() {
    return {
      loading: true,
      showFilters: false,
      filter: {
        status: [],
        structures: [],
        substructures: [],
        search: "",
        page: 1,
      },
      statusArr: ["pending", "accepted", "rejected", "max_resent", "expired"],
    };
  },
  computed: {
    ...mapGetters("invitations", { invitations: "get_invitations" }),
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    ...mapGetters("auth", { auth_sources: "get_auth_sources" }),
    invitationsFiltered() {
      if (this.invitations) {
        return this.invitations
          .filter((invitation) => {
            let check = true;
            let hasAssignedSubstructures = false;
            let hasSelectStructure = false;

            if (invitation.assigned_substructure_ids) {
              this.filter.substructures.forEach((structure) => {
                if (invitation.assigned_substructure_ids.includes(structure))
                  hasAssignedSubstructures = true;
              });
            }
            if (invitation.select_structure_ids) {
              this.filter.structures.forEach((structure) => {
                if (invitation.select_structure_ids.includes(structure))
                  hasSelectStructure = true;
              });
            }

            if (
              (this.filter.status.length > 0 &&
                !this.filter.status.includes(invitation.status)) ||
              !invitation.email
                .toLowerCase()
                .includes(this.filter.search.toLowerCase()) ||
              (this.filter.substructures.length > 0 &&
                !hasAssignedSubstructures) ||
              (this.filter.structures.length > 0 && !hasSelectStructure)
            )
              check = false;

            return check;
          })
          .sort((a, b) => b.created_at - a.created_at);
      } else return [];
    },
    activeFilters() {
      let search = this.filter.search.length > 0 ? 1 : 0;
      return (
        search +
        this.filter.status.length +
        this.filter.structures.length +
        this.filter.substructures.length
      );
    },
    visibleStructures() {
      if (["superadmin", "admin"].includes(this.role)) {
        return this.structures;
      }

      if (this.auth) {
        return this.structures.filter((structure) => {
          let visibleSubstructures = structure.substructures.filter(
            (substructure) => {
              return this.auth.related_substructures.includes(substructure.id);
            }
          );
          return visibleSubstructures.length > 0;
        });
      }

      return [];
    },
    visibleSubstructures() {
      if (this.auth) {
        if (["superadmin", "admin"].includes(this.role)) {
          return this.substructures;
        }
        let visibleSubstructures = this.substructures.filter((substructure) => {
          return this.auth.related_substructures.includes(substructure.id);
        });
        return visibleSubstructures;
      }
      return [];
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.management.title"),
          disabled: false,
          to: { name: "Management" },
        },
        {
          text: this.$t("general.overview"),
          disabled: true,
        },
      ];
    },
    hideRegisterOptions() {
      if (!this.auth_sources) return false;
      return !this.auth_sources.filter((s) => s.name === "local")[0];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.storeFilter();
      },
    },
  },
  beforeMount() {
    if (this.hideRegisterOptions) {
      this.$router.push({ name: "Management" });
    }
    this.allow_roles(["superadmin", "admin", "manager"]);
    this.getFilter();
  },
  destroyed() {
    this.set_structures(null);
    this.set_invitations(null);
  },
  methods: {
    ...mapActions("structures", ["fetch_structures"]),
    ...mapActions("invitations", ["fetch_invitations"]),
    ...mapMutations("structures", ["set_structures"]),
    ...mapMutations("invitations", ["set_invitations"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_structures();
      await this.fetch_invitations();
      this.loading = false;
    },
    async showInvitation(invitation) {
      this.$router.push({
        name: "InvitationDetail",
        params: { id: invitation.id },
      });
    },
    getStatusColor(invitation) {
      if (invitation.status == "pending") return "warning";
      if (invitation.status == "accepted") return "success";
      if (invitation.status == "rejected") return "error";
      //max_resent?
      return "grey lighten-1";
    },
    structureById(id) {
      if (this.structures) {
        return this.structures.filter((structure) => {
          return structure.id == id;
        })[0];
      } else return [];
    },
    substructureById(id) {
      if (this.structures) {
        return this.substructures.filter((substructure) => {
          return substructure.id == id;
        })[0];
      } else return [];
    },
    resetFilter() {
      this.filter = {
        status: [],
        structures: [],
        substructures: [],
        search: "",
        page: 1,
      };
    },
    storeFilter() {
      localStorage.setItem("fil_inv", JSON.stringify(this.filter));
    },
    getFilter() {
      if (localStorage.getItem("fil_inv")) {
        let filter = JSON.parse(localStorage.getItem("fil_inv"));

        this.filter.status = filter.status ? filter.status : [];
        this.filter.structures = filter.structures ? filter.structures : [];
        this.filter.substructures = filter.substructures
          ? filter.substructures
          : [];
        this.filter.page = filter.page ? filter.page : 1;
      }
    },
  },
};
</script>

<style></style>
