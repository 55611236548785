<template>
  <v-dialog
    v-model="dialog"
    width="800"
    scrollable
    :persistent="loading"
    :disabled="loading || question.is_accepted || question.is_deleted"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @click:outside="loading ? null : closeDialog()"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 4 : 0"
            outlined
            style="transition: 0.4s"
            :disabled="loading"
          >
            <v-overlay
              v-if="question.is_accepted || question.is_deleted"
              absolute
              color="secondary"
              opacity=".8"
            >
              <div class="text-center">
                <h3>
                  {{
                    question.is_accepted
                      ? $t("general.accepted")
                      : $t("general.rejected")
                  }}
                </h3>
                <v-btn
                  small
                  outlined
                  :disabled="loading || disabled"
                  @click="
                    question.is_accepted ? acceptQuestion() : deleteQuestion()
                  "
                >
                  <span>{{ $t("general.undo") }}</span>
                  <v-icon small class="ml-2">mdi-replay</v-icon>
                </v-btn>
              </div>
            </v-overlay>
            <div class="d-flex align-center py-2 px-4">
              <v-icon small>mdi-comment-question</v-icon>
              <span class="overline ml-2">{{
                $t("resources.questions.singular")
              }}</span>
              <v-spacer />
              <v-slide-x-transition hide-on-leave>
                <div
                  v-show="
                    hover && !(question.is_accepted || question.is_deleted)
                  "
                >
                  <span class="caption mr-2">{{
                    $t("general.click_to_edit")
                  }}</span>
                  <v-icon small>mdi-pencil</v-icon>
                </div>
              </v-slide-x-transition>
              <v-slide-x-transition hide-on-leave>
                <div
                  v-show="
                    !hover && !question.is_accepted && !question.is_deleted
                  "
                >
                  <v-icon small>mdi-arrow-expand</v-icon>
                </div>
              </v-slide-x-transition>
            </div>
            <v-card-subtitle class="pt-0">{{
              question.bundle
            }}</v-card-subtitle>
            <v-card-title class="pt-1" style="word-break: break-word">{{
              question.body
            }}</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col
                  v-for="a in question.answers"
                  :key="`answer-${a.id}`"
                  cols="12"
                >
                  <v-list-item dense>
                    <v-list-item-icon>
                      <v-icon :color="a.is_correct ? 'success' : 'error'">{{
                        a.is_correct ? "mdi-check" : "mdi-close"
                      }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ a.body }}
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="pa-4">
              <v-btn
                small
                depressed
                :text="question.is_deleted"
                :color="question.is_deleted ? 'white' : 'error'"
                :disabled="loading || disabled"
                @click.stop="deleteQuestion()"
              >
                <span>{{ $t("resources.questions.review.reject") }}</span>
                <v-icon small class="ml-2">mdi-close-circle</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                small
                depressed
                :text="question.is_accepted"
                :color="question.is_accepted ? 'white' : 'success'"
                :disabled="loading || disabled"
                @click.stop="acceptQuestion()"
              >
                <span>{{ $t("resources.questions.review.accept") }}</span>
                <v-icon small class="ml-2">mdi-check-circle</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </div>
    </template>

    <v-card v-if="dialog" color="grey lighten-3">
      <v-card-title class="overline secondary--text">{{
        $t("resources.questions.editQuestion")
      }}</v-card-title>
      <v-card-subtitle>{{ question.bundle }}</v-card-subtitle>
      <v-card-text v-if="edited_question">
        <v-form v-model="valid.body">
          <v-row dense>
            <v-col cols="12" class="pt-2">
              <v-textarea
                v-model="edited_question.body"
                :label="$t('resources.questions.body')"
                outlined
                background-color="white"
                dense
                no-resize
                height="90"
                :disabled="answersHaveChanged || disabled"
                :rules="rules.body"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-btn
                  text
                  small
                  color="error"
                  :disabled="!questionHasChanged || disabled"
                  @click="edited_question.body = question.body"
                >
                  <span>{{ $t("general.reset") }}</span>
                </v-btn>
                <v-btn
                  text
                  small
                  color="success"
                  class="ml-2"
                  :disabled="
                    !questionHasChanged || !edited_question.body || disabled
                  "
                  @click="updateQuestionBody()"
                >
                  <span>{{ $t("general.saveQuestion") }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text>
        <v-form v-model="valid.answers">
          <v-row>
            <v-col
              v-for="(a, i) in edited_question.answers"
              :key="`answer-${a.id}`"
              cols="12"
              class="pt-4"
            >
              <v-textarea
                v-model="edited_question.answers[i].body"
                height="60"
                outlined
                background-color="white"
                dense
                no-resize
                :disabled="loading || questionHasChanged || disabled"
                :label="`${$t('resources.questions.answer')} ${i + 1}`"
                :rules="rules.answer"
              >
                <template #prepend>
                  <v-btn
                    small
                    icon
                    :disabled="loading || questionHasChanged || disabled"
                    @click="
                      edited_question.answers[i].is_correct =
                        !edited_question.answers[i].is_correct
                    "
                  >
                    <v-icon
                      :color="
                        edited_question.answers[i].is_correct
                          ? 'success'
                          : 'error'
                      "
                      >{{
                        edited_question.answers[i].is_correct
                          ? "mdi-check"
                          : "mdi-close"
                      }}</v-icon
                    >
                  </v-btn>
                </template>
                <template #append>
                  <v-tooltip
                    :disabled="!answerHasChanged(edited_question.answers[i].id)"
                    left
                  >
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          small
                          color="primary"
                          :disabled="
                            !answerHasChanged(edited_question.answers[i].id) ||
                            disabled
                          "
                          @click="resetAnswer(edited_question.answers[i].id)"
                        >
                          <v-icon small>mdi-replay</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.reset") }}</span>
                  </v-tooltip>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-expand-transition>
        <v-card-text v-if="moreThanOneCorrectAnswer">
          <v-alert type="error">
            {{ $t("gw.journey.correct_anwser_error") }}
          </v-alert>
        </v-card-text>
      </v-expand-transition>
      <v-card-actions>
        <v-btn small text :disabled="loading" @click="closeDialog()">{{
          $t("general.close")
        }}</v-btn>
        <v-spacer />
        <v-btn
          small
          color="success"
          depressed
          text
          :disabled="
            !answersHaveChanged ||
            loading ||
            moreThanOneCorrectAnswer ||
            disabled ||
            !valid.body ||
            !valid.answers
          "
          @click="updateQuestionAnswers()"
          >{{ $t("gw.journey.save_answers") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "GwJourneyQuestionCard",
  props: {
    question: {
      type: Object,
      required: true,
    },
    journeyId: {
      type: [String, Number],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      edited_question: null,
      loading: false,
      rules: {
        body: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 255) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 255 }),
        ],
        answer: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 255) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 255 }),
        ],
      },
      valid: {
        body: false,
        answers: false,
      },
    };
  },
  computed: {
    questionHasChanged() {
      var changed = false;
      if (this.question.body !== this.edited_question.body) changed = true;
      return changed;
    },
    answersHaveChanged() {
      var changed = false;
      this.question.answers.forEach((a, i) => {
        if (
          a.is_correct !== this.edited_question.answers[i].is_correct ||
          a.body !== this.edited_question.answers[i].body
        )
          changed = true;
      });
      return changed;
    },
    moreThanOneCorrectAnswer() {
      var correct_answers = this.edited_question.answers
        .map((a) => a.is_correct)
        .filter((b) => b);
      return correct_answers.length !== 1;
    },
  },
  watch: {
    dialog(v) {
      if (v)
        this.edited_question = JSON.parse(JSON.stringify({ ...this.question }));
    },
  },
  methods: {
    ...mapActions("ghostwriter", [
      "update_gw_journey_question",
      "update_gw_journey_question_answer",
    ]),
    closeDialog() {
      this.dialog = false;
    },
    async acceptQuestion() {
      this.loading = true;
      var payload = {
        id: this.question.id,
        is_accepted: !this.question.is_accepted,
        journey_id: this.journeyId,
      };
      var res = await this.update_gw_journey_question({ payload: payload });
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
      this.loading = false;
    },
    async deleteQuestion() {
      this.loading = true;
      var payload = {
        id: this.question.id,
        is_deleted: !this.question.is_deleted,
        journey_id: this.journeyId,
      };
      var res = await this.update_gw_journey_question({ payload: payload });
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
      this.loading = false;
    },
    async updateQuestionBody() {
      this.loading = true;
      var payload = {
        id: this.question.id,
        body: this.edited_question.body,
        journey_id: this.journeyId,
      };
      var res = await this.update_gw_journey_question({ payload: payload });
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
      this.loading = false;
    },
    updateQuestionAnswers() {
      var answers = this.edited_question.answers;
      answers.forEach((a) => {
        if (this.answerHasChanged(a.id)) {
          this.updateQuestionAnswer(a);
        }
      });
    },
    async updateQuestionAnswer(answer) {
      this.loading = true;
      var payload = {
        id: answer.id,
        body: answer.body,
        is_correct: answer.is_correct,
        journey_id: this.journeyId,
      };
      var res = await this.update_gw_journey_question_answer({
        payload: payload,
      });
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
      this.loading = false;
    },
    answerHasChanged(id) {
      var changed = false;
      this.question.answers.forEach((a, i) => {
        if (
          a.id === id &&
          (a.is_correct !== this.edited_question.answers[i].is_correct ||
            a.body !== this.edited_question.answers[i].body)
        )
          changed = true;
      });
      return changed;
    },
    resetAnswer(id) {
      this.question.answers.forEach((a, i) => {
        if (a.id === id) {
          this.edited_question.answers[i].body = this.question.answers[i].body;
          this.edited_question.answers[i].is_correct =
            this.question.answers[i].is_correct;
        }
      });
    },
  },
};
</script>
