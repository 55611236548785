<template>
  <div>
    <section>
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.editJourney.title')"
        :subtitle="$t('resources.journeys.sectionHeaders.editJourney.subtitle')"
      >
        <template #action>
          <LanguageChooserInline
            hidedetails
            small
            :initial="language"
            @change="changeLanguage"
          />
        </template>
      </SectionHeader>

      <v-container>
        <v-row>
          <v-expand-transition>
            <v-col
              v-if="['unpublished', 'published'].includes(journey.status)"
              cols="12"
            >
              <JourneyStepper :journey="journey" />
            </v-col>
          </v-expand-transition>
        </v-row>
      </v-container>

      <JourneySettingsForm />
    </section>

    <section v-if="['superadmin', 'admin', 'editor'].includes(role)">
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.editActions.title')"
      />
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-card outlined>
              <v-card-title class="secondary--text">{{
                $t("resources.journeys.duplicate_journey")
              }}</v-card-title>
              <v-card-subtitle>{{
                $t("resources.journeys.duplicate_journey_subtitle")
              }}</v-card-subtitle>
              <v-card-actions>
                <v-spacer />
                <DuplicateJourneyDialog :journey="journey">
                  <template #button="{ openDialog }">
                    <v-btn text small color="primary" @click="openDialog()">
                      <span>{{ $t("general.copy") }}</span>
                    </v-btn>
                  </template>
                </DuplicateJourneyDialog>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card outlined>
              <v-card-title class="secondary--text">{{
                $t("gw.journey.delete.title")
              }}</v-card-title>
              <v-card-subtitle>{{
                $t("ng.journey.delete_journey_subtitle")
              }}</v-card-subtitle>
              <v-card-actions>
                <v-spacer />
                <v-tooltip
                  top
                  color="primary"
                  :disabled="!['active'].includes(journey.status)"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-inline-block" v-bind="attrs" v-on="on">
                      <v-btn
                        text
                        color="error"
                        :disabled="['active'].includes(journey.status)"
                        small
                        @click="openDeleteDialog()"
                      >
                        {{ $t("resources.journeys.deleteJourney") }}
                      </v-btn>
                    </div>
                  </template>
                  <span>{{ $t("general.delete") }}</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <JourneyDialogDelete
      :journey="journey"
      :dialog="showDeleteDialog"
      @close="closeDeleteDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import JourneySettingsForm from "@/components/journeys/forms/JourneySettingsForm";
import JourneyDialogDelete from "@/components/journeys/dialogs/JourneyDialogDelete";
import DuplicateJourneyDialog from "@/components/journeys/dialogs/DuplicateJourneyDialog";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";
import JourneyStepper from "@/components/journeys/JourneyStepper";

export default {
  name: "JourneySettings",
  components: {
    SectionHeader,
    JourneySettingsForm,
    JourneyDialogDelete,
    DuplicateJourneyDialog,
    LanguageChooserInline,
    JourneyStepper,
  },
  data() {
    return {
      loading: false,
      showDeleteDialog: false,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      language: "get_selected_language",
    }),
  },
  methods: {
    ...mapActions("journeys", ["duplicate_journey"]),
    ...mapMutations("journeys", ["set_selected_language"]),
    closeDeleteDialog(wasDeleted) {
      this.showDeleteDialog = false;
      if (wasDeleted) this.$router.push({ name: "JourneyCurrent" });
    },
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    async duplicateJourney() {
      let payload = { journey_id: this.journey.id };
      let res = await this.duplicate_journey({
        payload: payload,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    async changeLanguage(lang) {
      this.set_selected_language(lang);
    },
  },
};
</script>

<style></style>
