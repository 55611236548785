<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.nuggets.new')"
      icon="mdi-school"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          exact
          color="grey lighten-2"
          :to="{ name: 'NuggetOverview' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>
    <!-- <ViewLoader v-if="!categories" /> -->

    <v-form v-model="valid">
      <!-- <section v-if="categories"> -->
      <section>
        <SectionHeader
          :title="$t('ng.nuggets.general')"
          :subtitle="$t('ng.nuggets.general_settings')"
        />

        <v-container>
          <v-row>
            <v-col cols="12" sm="4" md="3">
              <v-select
                v-model="input.type"
                :items="nuggetTypes"
                outlined
                background-color="white"
                dense
                :label="$t('resources.nuggets.type')"
                :menu-props="{ bottom: true, offsetY: true }"
              />
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <LanguageChooserInline
                hidedetails
                small
                colored
                :initial="input.language"
                @change="changeLanguage"
              />
            </v-col>
            <v-col cols="12" sm="4" md="6">
              <CategorySelector @change="setCategory" />
            </v-col>
          </v-row>
        </v-container>
      </section>

      <v-divider v-if="!loading" class="my-8" />

      <section>
        <SectionHeader
          :title="$t('ng.add_nugget_title')"
          :subtitle="$t('ng.nuggets.content_main')"
        />
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <InputTextfield
                v-model="input.title"
                :label="$t('resources.nuggets.title')"
                listen-to-input
                :rules="input.formRules.title"
              />
            </v-col>
            <v-col cols="12">
              <InputTextarea
                v-model="input.description"
                :label="$t('resources.nuggets.description')"
                listen-to-input
                height="80"
                :rules="input.formRules.description"
              />
            </v-col>
            <v-col
              v-if="['text', 'video'].includes(input.type)"
              cols="12"
              class="mt-4"
            >
              <h4 class="secondary--text font-weight-light">
                {{ $t("resources.nuggets.content") }}
              </h4>
              <v-expand-transition>
                <p
                  v-if="input.type === 'video'"
                  class="secondary--text text-body-2"
                >
                  {{ $t("resources.nuggets.optional_content") }}
                </p>
              </v-expand-transition>

              <v-expand-transition>
                <v-alert
                  v-if="input.bodyImgCount() !== input.images.length"
                  text
                  type="error"
                  dense
                  colored-border
                  border="left"
                >
                  <div class="caption">
                    <span
                      v-html="
                        $t('ng.image_placeholder_hint', {
                          placeholder: `<span class='primary pa-1 white--text font-weight-bold'>[_IMG_]</span>`,
                          count: input.bodyImgCount(),
                          images: input.images.length,
                        })
                      "
                    />
                  </div>
                </v-alert>
              </v-expand-transition>

              <InputTextarea
                v-model="input.content"
                :label="$t('resources.nuggets.content')"
                listen-to-input
                :body-format="input.body_format"
                :custom-body-formats="['html', 'math']"
                :rules="input.formRules.content"
                html
                vertical
                @add-image-placeholder="addImagePlaceholder"
                @change-format="setFormat"
              >
                <template #actions="{ changeFormat, addImagePlaceholder }">
                  <div
                    class="d-flex align-center justify-space-between"
                    style="width: 100%"
                  >
                    <div class="caption grey lighten-3 py-2 px-3">
                      {{ !loading ? `Format: ${input.body_format}` : "" }}
                    </div>
                    <div class="d-flex">
                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-btn
                              small
                              :disabled="loading"
                              depressed
                              color="primary"
                              @click="changeFormat()"
                            >
                              <v-icon small>
                                mdi-format-letter-case-upper
                              </v-icon>
                              <span class="ml-2">{{
                                $t("ng.change_format")
                              }}</span>
                            </v-btn>
                          </div>
                        </template>
                        <span>{{
                          `${$t("ng.change_format")} : ${input.body_format}`
                        }}</span>
                      </v-tooltip>

                      <v-btn
                        small
                        :disabled="loading"
                        depressed
                        color="primary"
                        class="ml-2"
                        @click="addImagePlaceholder()"
                      >
                        <v-icon small> mdi-image </v-icon>
                        <span class="ml-2">{{
                          $t("ng.add_image_placeholder")
                        }}</span>
                      </v-btn>
                      <NuggetPreview v-if="false" :nugget="input">
                        <template #activator="{ showDialog }">
                          <v-btn icon @click="showDialog()">
                            <v-icon small> mdi-file-find </v-icon>
                          </v-btn>
                        </template>
                      </NuggetPreview>
                    </div>
                  </div>
                </template>
              </InputTextarea>
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-row v-if="input.body_format === 'math'">
              <v-col cols="12">
                <div class="caption secondary--text">
                  {{ $t("ng.nugget_math_translation_hint") }}
                </div>
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-row v-if="['text', 'video'].includes(input.type)">
            <v-col
              v-for="(hash, i) in input.images_hash"
              :key="i"
              cols="6"
              sm="4"
              md="3"
            >
              <ImageUpload
                height="120"
                :image-hash="hash"
                :label="`Bild ${i + 1}`"
                @image-changed="contentImageChange(i, ...arguments)"
              />
            </v-col>
            <v-col cols="6" sm="4" md="3">
              <ImageUpload
                resetable
                height="120"
                :label="$t('resources.journeys.new_status')"
                card-color="grey lighten-2"
                @image-changed="contentImageChange(-1, ...arguments)"
              />
            </v-col>
          </v-row>

          <v-row v-if="input.type === 'iframe'">
            <v-col cols="12">
              <v-text-field
                v-model="input.media_uid"
                outlined
                dense
                background-color="white"
                :label="$t('resources.nuggets.iframe_link.label')"
                :hint="$t('resources.nuggets.iframe_link.hint')"
                :rules="input.formRules.content"
              />
            </v-col>
          </v-row>
        </v-container>
      </section>

      <v-divider v-if="!loading" class="my-8" />

      <!-- <section v-if="categories"> -->
      <section>
        <SectionHeader
          :title="$t('resources.media.description')"
          :subtitle="$t('ng.nuggets.media_main')"
        />
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <ImageUpload
                card-color="grey lighten-2"
                :image-hash="input.image_hash"
                :label="$t('resources.nuggets.image')"
                @image-changed="imageChange"
              />
              <v-alert
                v-if="!input.image"
                type="info"
                text
                class="mt-2"
                dense
                colored-border
                border="left"
              >
                <span class="caption">{{ $t("ng.nugget_image_hint") }}</span>
              </v-alert>
            </v-col>

            <v-col v-if="input.type === 'video'" cols="12" sm="6">
              <VideoUpload @video-changed="videoChange" />

              <v-alert
                type="info"
                text
                class="mt-2"
                dense
                colored-border
                border="left"
              >
                <span class="caption">{{
                  $t("ng.nuggets.video_translation_hint")
                }}</span>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <v-divider v-if="!loading" class="my-8" />

      <!-- <section v-if="categories"> -->
      <section>
        <SectionHeader
          :title="$t('resources.nuggets.links')"
          :subtitle="$t('ng.nuggets.add_links')"
        />
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-csv-field
                v-model="input.links"
                :label="$t('resources.nuggets.addLink')"
              />
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12" class="d-flex align-center">
              <v-spacer />
              <v-btn
                color="error"
                text
                small
                :disabled="loading"
                @click="resetForm"
              >
                {{ $t("general.clear") }}
              </v-btn>
              <v-btn
                color="success"
                depressed
                small
                class="ml-2"
                :loading="loading"
                :disabled="
                  !valid ||
                  loading ||
                  input.bodyImgCount() !== input.images.length ||
                  (input.type === 'video' && !input.source) ||
                  (input.type === 'text' && !input.content)
                "
                @click="saveForm"
              >
                <v-icon small class="mr-2"> mdi-content-save </v-icon>
                <span>{{ $t("general.save") }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Nugget } from "@/models/Nugget.js";

import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
/* import ViewLoader from "@/components/_layout/ViewLoader.vue"; */
import SectionHeader from "@/components/_layout/SectionHeader";
import ImageUpload from "@/components/global/ImageUpload";
import VideoUpload from "@/components/global/VideoUpload";
import NuggetPreview from "@/components/nuggets/NuggetPreview";
import CategorySelector from "@/components/global/CategorySelector";

export default {
  name: "NuggetAdd",
  components: {
    LanguageChooserInline,
    ViewHeader,
    /* ViewLoader, */
    SectionHeader,
    ImageUpload,
    VideoUpload,
    NuggetPreview,
    CategorySelector,
  },
  data() {
    return {
      valid: false,
      loading: false,
      input: null,
    };
  },
  computed: {
    nuggetTypes() {
      var types = ["text", "video"];
      if (process.env.VUE_APP_EMBEDDED_NUGGETS === "true") types.push("iframe"); // TODO: unused and not supported anymore atm
      return types;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.content.title"),
          disabled: false,
          to: { name: "Content" },
        },
        {
          text: this.$t("resources.nuggets.plural"),
          disabled: false,
          to: { name: "NuggetOverview" },
        },
      ];
    },
  },
  beforeMount() {
    this.setNugget();
  },
  methods: {
    ...mapActions("nuggets", ["add_nugget"]),
    setNugget() {
      this.input = new Nugget();
    },
    changeLanguage(value) {
      this.input.language = value;
    },
    resetForm() {
      this.setNugget();
    },
    setFormat(format) {
      this.input.body_format = format;
    },
    async saveForm() {
      this.loading = true;
      let nugget = { ...this.input };
      if (nugget.type == "text") {
        nugget.source = "local";
      }
      if (nugget.type == "video") {
        var langs = this.$i18n.availableLocales;
        var media_uids = {};
        langs.forEach((lang) => {
          media_uids[lang] = nugget.media_link;
        });
        nugget.media_uids = media_uids;
      }
      if (nugget.type === "iframe") {
        nugget.source = "moodle";
      }
      nugget.links = nugget.links.split(",");
      delete nugget.id;
      delete nugget.category;
      delete nugget.created_at;
      delete nugget.proposer_id;
      delete nugget.images_hash;
      delete nugget.media_uid;
      delete nugget.media_link;

      let res = await this.add_nugget({
        payload: nugget,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
        this.$router.push({
          name: "NuggetDetail",
          params: { id: res.nugget_id },
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    imageChange(imageObj) {
      this.errors = [];
      this.input.image = imageObj.filename;
    },
    videoChange(type, link, uuid) {
      this.errors = [];
      this.input.source = type;
      this.input.media_link = link || uuid;
    },
    addImagePlaceholder() {
      this.input.content += " [_IMG_] ";
    },
    contentImageChange(idx, val) {
      let images = [];
      let hashes = [];

      if (!val) {
        this.input.images.forEach((image, i) => {
          if (i !== idx) {
            images.push(image);
            hashes.push(this.input.images_hash[i]);
          }
        });
      }
      if (val) {
        images = [...this.input.images];
        hashes = [...this.input.images_hash];
        images.push(val.filename);
        hashes.push(val.hash);
      }
      this.input.images = [...images];
      this.input.images_hash = [...hashes];
    },
    setCategory(v) {
      this.input.category_id = v ? v : -1;
    },
  },
};
</script>
