import { render, staticRenderFns } from "./CategoryOverview.vue?vue&type=template&id=546d0e5c&scoped=true"
import script from "./CategoryOverview.vue?vue&type=script&lang=js"
export * from "./CategoryOverview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546d0e5c",
  null
  
)

export default component.exports