<template>
  <div>
    <section v-if="!!session" class="mt-4">
      <SectionHeader
        :title="$t('gw.parts')"
        :subtitle="$t('gw.sessionDetail.detailView')"
      >
        <template #action>
          <div class="d-flex align-center">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar tile size="24" v-bind="attrs" v-on="on">
                  <country-flag
                    :country="flagName(session.options.language)"
                    size="small"
                  />
                </v-avatar>
              </template>
              <span>{{
                $t("gw.selectedLanguage", {
                  lang: $t(`languages.${session.options.language}.display`),
                })
              }}</span>
            </v-tooltip>
            <v-btn
              depressed
              small
              color="secondary"
              class="ml-2"
              :to="{ name: 'GwSessionImport', params: { id: id } }"
            >
              <v-icon small class="mr-2"> mdi-import </v-icon>
              <span>{{ $t("gw.sessionDetail.importContent") }}</span>
            </v-btn>
          </div>
        </template>
      </SectionHeader>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-alert
              v-if="!licences.ghostwriter || licenceExpired"
              text
              type="error"
              dense
              border="left"
            >
              <span class="caption">{{ $t("gw.no_licence") }}</span>
            </v-alert>
            <GwSessionParts
              :parts="session.parts"
              :session-id="session.id"
              :options="session.options"
              :disabled="
                !licences.ghostwriter || licenceExpired || session.status !== 0
              "
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GwSessionParts from "@/components/ghostwriter/GwSessionParts";
import SectionHeader from "@/components/_layout/SectionHeader";
import CountryFlag from "vue-country-flag";

export default {
  name: "GwSessionDetail",
  components: {
    GwSessionParts,
    SectionHeader,
    CountryFlag,
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_session" }),
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    ...mapGetters("auth", { licences: "get_licences" }),
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
    title() {
      if (!this.session || !this.session.name) return "Sessiondetails";
      return this.session.name;
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor", "manager"]);
  },
  methods: {
    flagName(lang) {
      if (lang === "zh-ZH") return "cn"; // chinese flag fallback
      if (lang === "uk-UK") return "ua"; // ukrainian flag fallback
      if (lang === "ko-KO") return "kr"; // korean flag fallback
      return lang.slice(-2);
    },
  },
};
</script>
