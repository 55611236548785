<template>
  <div>
    <ViewHeader :breadcrumbs="breadcrumbs" :title="title" icon="mdi-typewriter">
      <template #titleButton>
        <div class="d-flex align-center">
          <GwCreditInfoMenu />
          <v-btn
            x-small
            depressed
            exact
            color="grey lighten-2"
            class="ml-2"
            :to="{ name: 'GwSessionList' }"
          >
            {{ $t("general.toOverview") }}
          </v-btn>
        </div>
      </template>

      <template #extension>
        <ViewNavigation :nav-items="navItems" only-tabs class="flex-grow-1" />
      </template>
    </ViewHeader>

    <ViewLoader v-if="loading" />

    <v-slide-y-transition>
      <div v-if="!loading">
        <router-view />
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import GwCreditInfoMenu from "@/components/ghostwriter/GwCreditInfoMenu";
import ViewNavigation from "@/components/_layout/ViewNavigation";

export default {
  name: "GwSessionDetail",
  components: {
    ViewHeader,
    ViewLoader,
    GwCreditInfoMenu,
    ViewNavigation,
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_session" }),
    title() {
      if (!this.session || !this.session.name) return "";
      return this.session.name;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("gw.ghostwriter"),
          disabled: false,
          to: { name: "GwSessionList" },
        },
        {
          text: this.$t("gw.modes.single"),
          disabled: false,
          to: { name: "GwSessionList" },
        },
        {
          text: this.$t("general.details"),
          disabled: true,
        },
      ];
    },
    navItems() {
      return [
        {
          title: this.$t("resources.journeys.navItems.contents"),
          link: { name: "GwSessionMain" },
          disabled: this.loading || (this.session && this.session.status === 3),
        },
        {
          title: this.$t("resources.invitations.settings"),
          link: { name: "GwSessionSettings" },
          disabled: this.loading || (this.session && this.session.status === 3),
        },
        {
          title: this.$t("gw.import"),
          link: { name: "GwSessionImport" },
          disabled: this.loading,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor", "manager"]);
  },
  destroyed() {
    this.set_session(null);
    this.set_categories([]);
  },
  methods: {
    ...mapActions("ghostwriter", ["fetch_session"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("ghostwriter", ["set_session"]),
    ...mapMutations("categories", ["set_categories"]),
    async fetchData() {
      if (!Number(this.id)) return false;
      this.loading = true;
      await this.fetch_categories();
      await this.fetch_session({
        id: this.id,
      });
      if (!!this.session && this.session.disabled) {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("gw.invalid_session_error"),
        });
        this.$router.push({ name: "GwSessionList" });
      }
      this.loading = false;
    },
  },
};
</script>

<style></style>
