/* export const set_upload_token = (state, value) => {
  state.upload_token = value;
};
export const set_download_token = (state, value) => {
  state.download_token = value;
}; */

export const set_files = (state, value) => {
  state.files = value;
};

export const set_file = (state, value) => {
  state.file = value;
};
