<template>
  <div>
    <v-container v-if="statistics" class="pt-0">
      <v-row>
        <v-col cols="12">
          <v-card outlined :loading="!statistics">
            <v-card-title class="py-1">
              <v-chip
                small
                :close="activeFilters > 0"
                :color="activeFilters ? 'primary' : ''"
                @click:close="resetFilter"
              >
                {{ activeFilters }} {{ $t("general.filtersSelected") }}
              </v-chip>
              <v-spacer />
              <v-btn
                v-if="display.filters"
                icon
                @click="display.filters = !display.filters"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
              <v-btn
                v-if="!display.filters"
                icon
                @click="display.filters = !display.filters"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text v-if="display.filters">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="filter.status"
                    :items="options.status"
                    attach
                    chips
                    small-chips
                    deletable-chips
                    item-text="name"
                    item-value="type"
                    :label="$t('resources.journeys.status')"
                    prepend-icon="mdi-list-status"
                    :placeholder="$t('general.all')"
                    multiple
                    outlined
                    dense
                    hide-details
                    :menu-props="{ bottom: true, offsetY: true }"
                  >
                    <template #item="{ item }">
                      {{ item.name }}
                    </template>
                  </v-select>
                </v-col>
                <v-col v-if="substructures" cols="12">
                  <v-select
                    v-model="filter.substructures"
                    :items="visibleSubstructures"
                    item-text="name"
                    item-value="id"
                    attach
                    chips
                    small-chips
                    deletable-chips
                    :label="$t('resources.substructures.plural')"
                    prepend-icon="mdi-select-group"
                    :placeholder="$t('general.all')"
                    multiple
                    outlined
                    dense
                    hide-details
                    :menu-props="{ bottom: true, offsetY: true }"
                  >
                    <template #item="{ item }">
                      {{ substructureById(item.id).structure.name }} /
                      {{ item.name }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="search"
                    class="mt-0 pt-0"
                    prepend-icon="mdi-magnify"
                    outlined
                    dense
                    hide-details
                    append-icon="mdi-close"
                    :label="$t('general.search')"
                    @click:append="search = ''"
                    @keydown="page = 1"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card elevation="0" outlined>
            <v-data-table
              :headers="[
                { text: $t('resources.users.user_name'), value: 'user_name' },
                {
                  value: 'first_name',
                  text: '',
                  sortable: false,
                  width: '0%',
                },
                {
                  value: 'last_name',
                  text: '',
                  sortable: false,
                  width: '0%',
                },
                {
                  text: $t('resources.journeys.status'),
                  value: 'user_journey.status',
                },
                {
                  text: $t(
                    'resources.journeys.sectionHeaders.statsUser.subtitle'
                  ),
                  value: 'user_journey.progress',
                },
                {
                  text: $t('resources.journeys.quiz_results'),
                  value: 'user_journey.quiz_criteria',
                },
                {
                  text: '',
                  sortable: false,
                },
              ]"
              :items="statsFiltered"
              :items-per-page="10"
              :page.sync="page"
              :search="search"
              :loading="!statistics"
            >
              <template #item="stats">
                <tr>
                  <td colspan="3">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          `${stats.item.first_name} ${stats.item.last_name}`
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          stats.item.user_name
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </td>
                  <td>
                    <v-chip
                      small
                      :color="getUserProgressColor(stats.item.user_journey)"
                    >
                      {{
                        stats.item.user_journey
                          ? $t(
                              `resources.journeys.user_statuses.${stats.item.user_journey.status}`
                            )
                          : $t("resources.journeys.user_statuses.not_started")
                      }}
                    </v-chip>
                  </td>
                  <td>
                    <v-progress-linear
                      :color="stats.item.user_journey ? 'success' : 'secondary'"
                      :value="
                        stats.item.user_journey
                          ? (stats.item.user_journey.progress[0] /
                              stats.item.user_journey.progress[1]) *
                            100
                          : 0
                      "
                      height="8"
                    />
                  </td>
                  <td>
                    <v-chip small color="grey lighten-3">
                      {{
                        stats.item.user_journey &&
                        stats.item.user_journey.quiz_criteria !== null
                          ? stats.item.user_journey.quiz_criteria / 100
                          : "-"
                      }}
                      %
                    </v-chip>
                  </td>
                  <td>
                    <UserJourneyDialog :user="stats.item" :journey="journey">
                      <template #action="{ openDialog }">
                        <v-btn
                          x-small
                          color="info"
                          :disabled="!stats.item.user_journey"
                          @click="openDialog()"
                          >{{ $t("general.details") }}</v-btn
                        >
                      </template>
                    </UserJourneyDialog>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserJourneyDialog from "@/components/users/UserJourneyDialog.vue";

export default {
  name: "JourneyStatsUser",
  components: {
    UserJourneyDialog,
  },
  data() {
    return {
      search: "",
      page: 1,
      display: {
        filters: false,
      },
      filter: {
        substructures: [],
        status: [],
      },
      options: {
        status: [
          {
            type: "not_started",
            name: this.$t("resources.journeys.user_statuses.not_started"),
          },
          {
            type: "in_progress",
            name: this.$t("resources.journeys.user_statuses.in_progress"),
          },
          {
            type: "finished",
            name: this.$t("resources.journeys.user_statuses.finished"),
          },
          {
            type: "failed",
            name: this.$t("resources.journeys.user_statuses.failed"),
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      statistics: "get_journey_statistics",
    }),
    ...mapGetters("structures", { substructures: "get_substructures" }),
    statsFiltered() {
      if (this.statistics) {
        let filtered = this.statistics;

        if (this.filter.status.length > 0) {
          filtered = filtered.filter((user) => {
            return (
              (user.user_journey &&
                this.filter.status.includes(user.user_journey.status)) ||
              (!user.user_journey && this.filter.status.includes("not_started"))
            );
          });
        }

        if (this.filter.substructures.length > 0) {
          return filtered.filter((user) => {
            return (
              user.substructures.filter((substructure_id) => {
                return this.filter.substructures.includes(substructure_id);
              }).length > 0
            );
          });
        }

        return filtered;
      } else return [];
    },
    activeFilters() {
      let search = this.search.length > 0 ? 1 : 0;
      return (
        this.filter.status.length + this.filter.substructures.length + search
      );
    },
    visibleSubstructures() {
      return this.substructures.filter((substructure) =>
        this.journey.substructures.includes(substructure.id)
      );
    },
  },
  methods: {
    resetFilter() {
      this.filter.status = [];
      this.filter.substructures = [];
      this.search = "";
    },
    substructureById(id) {
      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    getUserProgressColor(user_journey) {
      if (!user_journey) return "grey lighten-3";
      if (user_journey.status === "finished") return "success";
      if (user_journey.status === "in_progress") return "warning";
      if (user_journey.status === "failed") return "error";
      return "secondary";
    },
    formatDate(string) {
      let dateObj = new Date(string);
      let date = dateObj.toLocaleDateString(this.$i18n.locale);
      let time = dateObj.toLocaleTimeString(this.$i18n.locale, {
        timeStyle: "short",
      });
      return `${date} ${time}`;
    },
  },
};
</script>

<style></style>
