<template>
  <div>
    <section>
      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-title class="secondary--text">{{
                $t("general.edit")
              }}</v-card-title>
              <v-card-subtitle>{{
                $t("gw.journey.editJourney.subtitle_2")
              }}</v-card-subtitle>
              <v-card-text>
                <v-form v-model="valid">
                  <v-text-field
                    v-model="title"
                    dense
                    outlined
                    background-color="white"
                    :label="$t('resources.nuggets.title')"
                    :rules="rules.title"
                    :disabled="
                      journey.is_imported ||
                      journey.is_deleted ||
                      journey.is_archived
                    "
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  small
                  text
                  color="success"
                  :loading="loading"
                  :disabled="
                    !titleHasChanged ||
                    !valid ||
                    journey.is_imported ||
                    journey.is_deleted ||
                    journey.is_archived
                  "
                  @click="updateJourney()"
                  >{{ $t("general.save") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="secondary--text">{{
                $t("general.delete")
              }}</v-card-title>
              <v-card-subtitle>{{
                $t("gw.journey.delete.subtitle_2")
              }}</v-card-subtitle>
              <v-card-actions>
                <v-spacer />
                <GwDeleteJourneyDialog :journey="journey">
                  <template #action="{ openDialog }">
                    <v-tooltip left>
                      <template #activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                          <v-btn
                            small
                            text
                            class="ml-2"
                            color="error"
                            @click="openDialog"
                          >
                            <span>{{ $t("general.delete") }}</span>
                            <!-- <v-icon x-small class="ml-2">mdi-delete</v-icon> -->
                          </v-btn>
                        </div>
                      </template>
                      <span>{{ $t("gw.journey.delete_journey") }}</span>
                    </v-tooltip>
                  </template>
                </GwDeleteJourneyDialog>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GwDeleteJourneyDialog from "@/components/ghostwriter/journeys/GwDeleteJourneyDialog";

export default {
  name: "GwJourneySettings",
  components: {
    GwDeleteJourneyDialog,
  },
  data() {
    return {
      loading: false,
      title: "",
      valid: false,
      rules: {
        title: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 64) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 64 }),
        ],
      },
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { journey: "get_gw_journey" }),
    titleHasChanged() {
      return this.title !== this.journey.title;
    },
  },
  mounted() {
    this.title = this.journey.title;
  },
  methods: {
    ...mapActions("ghostwriter", ["update_gw_journey"]),
    async updateJourney() {
      this.loading = true;
      var payload = {
        id: this.journey.id,
        title: this.title,
      };
      var res = await this.update_gw_journey({ payload: payload });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
    },
  },
};
</script>
