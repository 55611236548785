import axios from "axios";

let FILESERVERAPI_PREFIX = process.env.VUE_APP_FILE_SERVER_URL + "/";

if (process.env.NODE_ENV == "development") {
  FILESERVERAPI_PREFIX = "/fileserver/";
}

export default {
  async get(url, config = {}) {
    try {
      let res = await axios.get(FILESERVERAPI_PREFIX + url, config);
      var data = res.data instanceof Array ? { items: res.data } : res.data;
      data._status = res.status;
      data._headers = res.headers;
      return res.data;
    } catch (e) {
      console.error("Error in @/services/fileserverapi.js -> default.get()", e);
      return null;
    }
  },
  async post(url, payload, config = {}) {
    try {
      let res = await axios.post(FILESERVERAPI_PREFIX + url, payload, config);
      var data = res.data;
      data._status = res.status;
      return data;
    } catch (e) {
      console.error(
        "Error in @/services/fileserverapi.js -> default.post()",
        e
      );
      return null;
    }
  },
  async put(url, payload, config = {}) {
    try {
      let res = await axios.put(FILESERVERAPI_PREFIX + url, payload, config);
      return res.data;
    } catch (e) {
      console.error("Error in @/services/gwapi.js -> default.put()", e);
      return null;
    }
  },
  async patch(url, payload, config = {}) {
    try {
      let res = await axios.patch(FILESERVERAPI_PREFIX + url, payload, config);
      var data = res.data;
      data._status = res.status;
      return data;
    } catch (e) {
      console.error(
        "Error in @/services/fileserverapi.js -> default.patch()",
        e
      );
      return null;
    }
  },
  async delete(url, config = {}) {
    try {
      let res = await axios.delete(FILESERVERAPI_PREFIX + url, config);
      var data = res.data;
      data._status = res.status;
      return data;
    } catch (e) {
      console.error(
        "Error in @/services/fileserverapi.js -> default.delete()",
        e
      );
      return null;
    }
  },
};
