<template>
  <div>
    <v-tooltip top color="primary" :disabled="!disabled">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn
            color="primary"
            x-small
            text
            :disabled="disabled"
            @click="showDialog = true"
          >
            {{ $t("general.edit") }}
          </v-btn>
        </div>
      </template>
      <span>{{
        !disabled
          ? $t("resources.questions.activationHint_cannotBeEdited")
          : $t("resources.questions.activationHint_unsaved")
      }}</span>
    </v-tooltip>

    <v-dialog v-if="showDialog" v-model="showDialog" max-width="640">
      <v-card color="grey lighten-4" :loading="loading">
        <v-card-title class="secondary--text overline">
          {{ $t("resources.categories.singular") }}
        </v-card-title>
        <v-card-text class="py-2">
          <CategorySelector
            :input-value="nugget.category_id"
            @change="setCategory"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small text color="secondary" @click="showDialog = false">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            small
            text
            color="success"
            :disabled="!inputChanged || !selectedCategory"
            :loading="loading"
            @click="changeCategories()"
          >
            {{ $t("general.apply") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CategorySelector from "@/components/global/CategorySelector";

export default {
  name: "NuggetCategoryChanger",
  components: {
    CategorySelector,
  },
  props: {
    nugget: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      selectedCategory: 0,
      loading: false,
    };
  },
  computed: {
    inputChanged() {
      let changed = false;
      if (this.selectedCategory !== this.nugget.category.id) {
        changed = true;
      }
      return changed;
    },
  },
  methods: {
    ...mapActions("nuggets", ["patch_nugget_old"]),
    resetCategories() {
      this.selectedCategory = this.nugget.category
        ? this.nugget.category.id
        : -1;
    },
    async changeCategories() {
      this.loading = true;

      let payload = {
        id: this.nugget.id,
        category_id: this.selectedCategory,
        language: this.$i18n.locale,
      };

      let res = await this.patch_nugget_old({
        payload: payload,
        type: "category",
        language: this.$i18n.locale,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        this.showDialog = false;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    setCategory(v) {
      this.selectedCategory = v ? v : -1;
    },
  },
};
</script>
