<template>
  <div>
    <section>
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.editCertificate.title')"
        :subtitle="
          $t('resources.journeys.sectionHeaders.editCertificate.subtitle')
        "
      />

      <v-container>
        <v-row>
          <v-expand-transition>
            <v-col
              v-if="['unpublished', 'published'].includes(journey.status)"
              cols="12"
            >
              <JourneyStepper :journey="journey" />
            </v-col>
          </v-expand-transition>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="secondary--text">
                {{ $t("ng.journey.change_certificate_title") }}
              </v-card-title>
              <v-card-subtitle>
                {{ $t("ng.journey.change_certificate_subtitle") }}
              </v-card-subtitle>
              <v-card-text>
                <v-form v-model="valid">
                  <small
                    v-if="journey.status !== 'unpublished'"
                    class="grey--text font-weight-bold"
                    >{{ $t("resources.journeys.editWarning") }}</small
                  >
                  <v-autocomplete
                    v-model="input.certificate"
                    :items="visibleCertificates"
                    item-value="id"
                    return-object
                    outlined
                    hide-details
                    dense
                    item-text="name"
                    :disabled="
                      loading ||
                      journey.status !== 'unpublished' ||
                      !['editor', 'admin', 'superadmin'].includes(role)
                    "
                  />
                </v-form>
              </v-card-text>

              <v-card-actions class="pl-4">
                <v-btn
                  depressed
                  small
                  color="primary"
                  :disabled="loading"
                  :to="{ name: 'JourneyCertificates' }"
                  target="_blank"
                >
                  <v-icon small class="mr-2">mdi-open-in-new</v-icon>
                  <span>{{ $t("ng.journey.show_all_certificates") }}</span>
                </v-btn>
                <v-spacer />
                <v-btn
                  text
                  small
                  color="error"
                  :disabled="
                    !inputChanged ||
                    loading ||
                    !['editor', 'admin', 'superadmin'].includes(role)
                  "
                  @click="resetForm"
                >
                  {{ $t("general.cancel") }}
                </v-btn>
                <v-btn
                  text
                  small
                  color="primary"
                  :disabled="
                    !inputChanged ||
                    !valid ||
                    loading ||
                    !['editor', 'admin', 'superadmin'].includes(role)
                  "
                  @click="save"
                >
                  {{ $t("general.save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import JourneyStepper from "@/components/journeys/JourneyStepper";

export default {
  name: "JourneyCertificate",
  components: { SectionHeader, JourneyStepper },
  data() {
    return {
      loading: false,
      input: null,
      valid: false,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      certificates: "get_certificates",
    }),
    inputChanged() {
      let changed = false;
      if (this.input.certificate.id !== this.journey.certificate.id)
        changed = true;

      return changed;
    },
    visibleCertificates() {
      if (!this.certificates) return [];
      var certificates = [...this.certificates];
      return certificates.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  beforeMount() {
    this.input = { ...this.journey };
  },
  destroyed() {
    this.set_certificates([]);
  },
  methods: {
    ...mapActions("journeys", ["patch_journey", "fetch_certificates"]),
    ...mapMutations("journeys", ["set_certificates"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_certificates();
      this.loading = false;
    },
    resetForm() {
      this.input = { ...this.journey };
    },
    async save() {
      this.loading = true;
      let properties = [
        { name: "certificate_id", value: this.input.certificate.id },
      ];
      let payload = {
        journey_id: this.journey.id,
        language: this.$i18n.locale,
        properties: properties,
      };
      let res = await this.patch_journey({
        payload: payload,
      });
      this.loading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        this.$emit("update");
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
  },
};
</script>

<style></style>
