<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.questions.plural')"
      icon="mdi-comment-question"
      route-name="QuestionAdd"
    />
    <ViewLoader v-if="!questions" />

    <section v-if="questions">
      <SectionHeader :title="`${$t('general.overview')}`" />
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn icon @click="showFilter = !showFilter">
                  <v-icon>{{
                    showFilter ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="showFilter">
                <v-row>
                  <v-col v-if="categories" cols="12" sm="6">
                    <v-autocomplete
                      v-model="filter.categories"
                      :items="sortedCategories"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.categories.plural')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.question_type"
                      :items="options.question_type"
                      item-text="name"
                      item-value="value"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.questions.type')"
                      :placeholder="$t('general.all')"
                      prepend-icon="mdi-animation-play"
                      outlined
                      multiple
                      hide-details
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.status"
                      :items="options.status"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.users.status')"
                      :placeholder="$t('general.all')"
                      prepend-icon="mdi-animation-play"
                      outlined
                      multiple
                      hide-details
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.is_active"
                      :items="options.is_active"
                      item-text="name"
                      item-value="value"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.categories.isactive')"
                      :placeholder="$t('general.all')"
                      prepend-icon="mdi-comment-question"
                      outlined
                      multiple
                      hide-details
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <!-- <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="filter.is_accepted"
                      :items="options.is_accepted"
                      item-text="name"
                      item-value="value"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.questions.is_accepted')"
                      prepend-icon="mdi-checkbox-marked-outline"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      hide-details
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template
                        #item="{ item }"
                      >
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col> -->
                  <v-col cols="12" sm="6">
                    <v-switch
                      v-model="filter.reported"
                      :label="$t('ng.question_reports')"
                      class="mt-2"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      prepend-icon="mdi-magnify"
                      :label="$t('general.search')"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      @click:append="filter.search = ''"
                      @keydown="filter.page = 1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="headers"
                :items="filteredQuestions"
                :items-per-page="10"
                :page.sync="filter.page"
                :search="filter.search"
              >
                <template #item="{ item }">
                  <tr
                    @click="
                      $router.push({
                        name: 'QuestionDetail',
                        params: { id: item.id },
                      })
                    "
                  >
                    <td>
                      <v-icon
                        small
                        :color="item.is_active ? 'success' : 'error'"
                      >
                        mdi-checkbox-marked-circle-outline
                      </v-icon>
                    </td>

                    <td>
                      <v-chip label text x-small :color="item.status_color">
                        <span class="text-uppercase">{{
                          item.status_name
                        }}</span>
                      </v-chip>
                    </td>

                    <td>
                      <v-tooltip right color="secondary">
                        <template #activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-avatar size="16">
                              <v-img :src="item.type_image" />
                            </v-avatar>
                          </div>
                        </template>
                        <span class="caption">{{ item.type_name }}</span>
                      </v-tooltip>
                    </td>

                    <td>
                      <span class="caption text-uppercase">{{
                        item.body_format
                      }}</span>
                    </td>

                    <td>
                      <v-tooltip left color="secondary" max-width="400">
                        <template #activator="{ on }">
                          <div v-on="on">
                            <span
                              class="caption"
                              v-text="item.body.slice(0, 50)"
                            />
                            <span v-if="item.body.length > 50">...</span>
                          </div>
                        </template>
                        <div>
                          <h5>{{ $t("resources.invitations.preview") }}:</h5>
                          <div
                            v-if="['text', 'html'].includes(item.body_format)"
                            v-html="item.body"
                          />
                          <MathJaxRenderer
                            v-if="['math'].includes(item.body_format)"
                            :formula="item.body"
                            :safe="false"
                          />
                          <small>Format: {{ item.body_format }}</small>
                        </div>
                      </v-tooltip>
                    </td>

                    <td>
                      <div class="d-flex align-center">
                        <v-avatar
                          v-for="(category, i) in item.categories"
                          :key="i"
                          :size="i > 1 ? 24 : 16"
                        >
                          <v-tooltip right color="secondary">
                            <template #activator="{ on, attrs }">
                              <v-img
                                v-if="i <= 1"
                                v-bind="attrs"
                                :src="category.image"
                                v-on="on"
                              />
                            </template>
                            <span>{{ category.name }}</span>
                          </v-tooltip>
                          <v-tooltip v-if="i > 1" right color="secondary">
                            <template #activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon x-small> mdi-plus </v-icon>
                                <span class="caption">{{
                                  item.categories.length - 2
                                }}</span>
                              </div>
                            </template>
                            <div>
                              <div
                                v-for="preview in item.categories"
                                :key="preview.id"
                              >
                                {{ preview.name }}
                              </div>
                            </div>
                          </v-tooltip>
                        </v-avatar>
                      </div>
                    </td>

                    <td class="d-flex align-center">
                      <v-icon small> mdi-alarm </v-icon>
                      <span class="caption ml-2">{{ item.time }}s</span>
                    </td>

                    <td>
                      <v-tooltip left color="secondary">
                        <template #activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            :color="item.image ? 'secondary' : 'grey lighten-2'"
                            small
                            v-on="on"
                          >
                            mdi-image-album
                          </v-icon>
                        </template>
                        <span>{{ item.image ? "Mit Bild" : "Ohne Bild" }}</span>
                      </v-tooltip>
                      <v-tooltip left color="secondary">
                        <template #activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            :color="
                              item.explanation ? 'secondary' : 'grey lighten-2'
                            "
                            small
                            class="ml-2"
                            v-on="on"
                          >
                            mdi-file-document-alert
                          </v-icon>
                        </template>
                        <span>{{
                          item.explanation
                            ? $t("ng.with_explanation")
                            : $t("ng.without_explanation")
                        }}</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            :color="
                              item.reports.length > 0
                                ? 'error'
                                : 'grey lighten-2'
                            "
                            small
                            class="ml-2"
                            v-on="on"
                          >
                            mdi-alert
                          </v-icon>
                        </template>
                        <span
                          >{{ item.reports.length }}
                          {{ $t("resources.questions.has_reports") }}</span
                        >
                      </v-tooltip>
                    </td>
                    <td>
                      <v-tooltip left>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            small
                            :to="{
                              name: 'QuestionDetail',
                              params: { id: item.id },
                            }"
                            target="_blank"
                            @click.stop
                            v-on="on"
                          >
                            <v-icon small> mdi-open-in-new </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("ng.open_in_new_tab") }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";
import MathJaxRenderer from "@/components/global/MathJaxRenderer";

export default {
  name: "QuestionOverview",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
    MathJaxRenderer,
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: this.$t("resources.questions.isactive"),
          value: "is_active",
          width: "50px",
        },
        {
          text: this.$t("resources.users.status"),
          value: "status",
          width: "120px",
        },
        {
          text: this.$t("resources.questions.type"),
          value: "question_type",
          width: "24px",
        },
        {
          text: this.$t("ng.format"),
          value: "body_format",
          width: "10px",
        },
        {
          text: this.$t("resources.questions.singular"),
          value: "body",
          width: "auto",
        },
        {
          text: this.$t("resources.categories.plural"),
          value: "categories",
          width: "64px",
        },
        {
          text: this.$t("resources.questions.time"),
          value: "time",
          width: "50px",
        },
        {
          text: this.$t("ng.info"),
          sortable: false,
          width: "100px",
        },
        { text: "", sortable: false, width: "40px" },
      ],
      filter: {
        categories: [],
        is_active: [],
        search: "",
        question_type: [],
        /* is_accepted: [], */
        reported: false,
        status: [],
        page: 1,
      },
      showFilter: false,
      options: {
        is_active: [
          {
            id: 1,
            name: this.$t("resources.questions.not_isactive"),
            value: false,
          },
          { id: 2, name: this.$t("resources.questions.isactive"), value: true },
        ],
        question_type: [
          {
            id: 1,
            value: "gap_text",
            name: this.$t("resources.questions.types.4"),
          },
          {
            id: 2,
            value: "single_choice",
            name: this.$t("resources.questions.types.6"),
          },
          {
            id: 3,
            value: "multi_choice",
            name: this.$t("resources.questions.types.3"),
          },
          {
            id: 4,
            value: "estimation",
            name: this.$t("resources.questions.types.5"),
          },
        ],
        is_accepted: [
          //{value: null, name: this.$t('general.new')},
          {
            value: false,
            name: this.$t("resources.questions.not_is_accepted"),
          },
          { value: true, name: this.$t("resources.questions.is_accepted") },
        ],
        status: [
          { id: -1, value: "rejected", name: this.$t("ng.rejected") },
          //{ id: 0, name: 'new' },
          {
            id: 1,
            value: "accepted",
            name: this.$t("resources.questions.is_accepted"),
          },
          { id: 2, value: "review", name: this.$t("ng.in_review") },
          {
            id: 3,
            value: "editing",
            name: this.$t("resources.questions.needs_edition"),
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("questions", {
      questions: "get_questions",
      reportmode: "get_reportmode",
      reviewmode: "get_reviewmode",
    }),
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    activeFilters() {
      let search = this.filter.search.length > 0 ? 1 : 0;
      let categories = this.filter.categories.length > 0 ? 1 : 0;
      let types = this.filter.question_type.length > 0 ? 1 : 0;
      let reported = this.filter.reported ? 1 : 0;
      let status = this.filter.status.length > 0 ? 1 : 0;
      return (
        this.filter.is_active.length +
        search +
        categories +
        types +
        reported +
        status
      );
    },
    filteredQuestions() {
      if (!this.questions) return [];
      let filtered = [...this.questions];
      // hide status new:
      filtered = filtered.filter((question) => question.status !== 0);
      if (this.filter.is_active.length > 0) {
        filtered = filtered.filter((question) => {
          return this.filter.is_active.includes(question.is_active);
        });
      }
      if (this.filter.categories.length > 0) {
        filtered = filtered.filter((question) => {
          let included = false;
          question.categories.forEach((category) => {
            if (this.filter.categories.includes(category.id)) included = true;
          });
          return included;
        });
      }
      if (this.filter.question_type.length > 0) {
        filtered = filtered.filter((question) => {
          return this.filter.question_type.includes(question.question_type);
        });
      }
      if (this.filter.status.length > 0) {
        filtered = filtered.filter((question) => {
          return this.filter.status.includes(question.status);
        });
      }
      if (this.filter.reported) {
        filtered = filtered.filter((question) => {
          return question.reports.length > 0;
        });
      }
      return filtered;
    },
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return (categories = categories.sort((a, b) =>
        a.name.localeCompare(b.name)
      ));
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.content.title"),
          disabled: false,
          to: { name: "Content" },
        },
        {
          text: this.$t("resources.questions.plural"),
          disabled: true,
        },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.storeFilter();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
    this.getFilter();
  },
  destroyed() {
    this.set_questions(null);
    this.set_categories(null);
  },
  methods: {
    ...mapActions("questions", ["fetch_questions"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("questions", [
      "set_questions",
      "set_reviewmode",
      "set_reportmode",
    ]),
    ...mapMutations("categories", ["set_categories"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_questions();
      await this.fetch_categories();
      this.loading = false;
    },
    resetFilter() {
      this.filter = {
        categories: [],
        is_active: [],
        search: "",
        question_type: [],
        reported: false,
        status: [],
        page: 1,
      };
    },
    storeFilter() {
      localStorage.setItem("fil_que", JSON.stringify(this.filter));
    },
    getFilter() {
      if (this.reportmode) {
        this.resetFilter();
        this.filter.reported = true;
      }
      if (this.reviewmode) {
        this.resetFilter();
        this.filter.status = [2];
      }
      if (
        !this.reportmode &&
        !this.reviewmode &&
        localStorage.getItem("fil_que")
      ) {
        let filter = JSON.parse(localStorage.getItem("fil_que"));

        this.filter.categories = filter.categories ? filter.categories : [];
        this.filter.is_active = filter.is_active ? filter.is_active : [];
        this.filter.search = filter.search ? filter.search : "";
        this.filter.question_type = filter.question_type
          ? filter.question_type
          : [];
        this.filter.reported = filter.reported ? true : false;
        this.filter.status = filter.status ? filter.status : [];
        this.filter.page = filter.page ? filter.page : 1;
      }
      this.set_reportmode(false);
      this.set_reviewmode(false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
