<template>
  <div>
    <v-expansion-panels v-model="openedPanels" flat multiple>
      <v-expansion-panel
        v-for="(b, idx) in visibleBundles"
        :key="`bundleaccordeon-${b.id}`"
        class="transparent"
      >
        <v-expansion-panel-header
          :color="openedPanels.includes(idx) ? 'transparent' : 'grey lighten-3'"
          style="transition: 0.2s"
        >
          <v-list-item dense>
            <v-list-item-icon>
              <v-icon
                small
                :color="allContentChecked(b) ? 'success' : 'secondary'"
                >{{
                  allContentChecked(b) ? "mdi-check-circle" : "mdi-alert"
                }}</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content style="width: 50%">
              <v-list-item-title class="d-flex">
                <span>{{ `${b.title}` }}</span>

                <GwJourneyBundleEditDialog :bundle="b">
                  <template #button="{ openDialog }">
                    <v-btn
                      icon
                      x-small
                      class="ml-2"
                      :disabled="
                        journey.is_imported ||
                        journey.is_deleted ||
                        journey.is_archived
                      "
                      @click.stop="openDialog()"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </GwJourneyBundleEditDialog>
              </v-list-item-title>
            </v-list-item-content>
            <div>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    small
                    label
                    class="mx-2"
                    v-bind="attrs"
                    v-on="on"
                    :color="
                      uncheckedContentLength(b.nuggets) === 0 ? 'success' : ''
                    "
                    style="width: 56px"
                  >
                    <v-icon x-small class="mr-2"> mdi-school </v-icon>
                    <span
                      v-if="uncheckedContentLength(b.nuggets) > 0"
                      class="overline"
                      >{{ uncheckedContentLength(b.nuggets) }}</span
                    >
                    <v-icon
                      v-if="uncheckedContentLength(b.nuggets) === 0"
                      x-small
                      >mdi-check</v-icon
                    >
                  </v-chip>
                </template>
                <span>{{
                  uncheckedContentLength(b.nuggets) === 0
                    ? $t("gw.journey.nuggets_checked_all")
                    : $t("gw.journey.nuggets_checked_notall")
                }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    small
                    label
                    v-on="on"
                    v-bind="attrs"
                    :color="
                      uncheckedContentLength(b.questions) === 0 ? 'success' : ''
                    "
                    style="width: 56px"
                  >
                    <v-icon x-small class="mr-2"> mdi-comment-question </v-icon>

                    <span v-if="uncheckedContentLength(b.questions) > 0">{{
                      uncheckedContentLength(b.questions)
                    }}</span>
                    <v-icon
                      v-if="uncheckedContentLength(b.questions) === 0"
                      x-small
                      >mdi-check</v-icon
                    >
                  </v-chip>
                </template>
                <span>{{
                  uncheckedContentLength(b.questions) === 0
                    ? $t("gw.journey.questions_checked_all")
                    : $t("gw.journey.questions_checked_notall")
                }}</span>
              </v-tooltip>
            </div>
          </v-list-item>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-2">
          <v-row>
            <v-col
              cols="12"
              v-for="(n, i) in getBundleNuggets(b)"
              :key="`gwjn-${i}`"
            >
              <GwJourneyNuggetCard
                :nugget="n"
                :journey-id="journey.id"
                :disabled="
                  journey.is_imported ||
                  journey.is_deleted ||
                  journey.is_archived
                "
                :bundle-images="previewImages(b)"
              />
            </v-col>
            <v-col
              cols="12"
              v-for="(q, i) in getBundleQuestions(b)"
              :key="`gwjq-${i}`"
            >
              <GwJourneyQuestionCard
                :question="q"
                :journey-id="journey.id"
                :disabled="
                  journey.is_imported ||
                  journey.is_deleted ||
                  journey.is_archived
                "
              />
            </v-col>
          </v-row>
          <v-col
            v-if="
              getBundleNuggets(b).length === 0 &&
              getBundleQuestions(b).length === 0
            "
          >
            <div class="text-center">
              {{ $t("gw.journey.no_content_left") }}
            </div>
          </v-col>
        </v-expansion-panel-content>
        <v-divider v-if="idx < visibleBundles.length - 1" />
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GwJourneyNuggetCard from "@/components/ghostwriter/journeys/GwJourneyNuggetCard";
import GwJourneyQuestionCard from "@/components/ghostwriter/journeys/GwJourneyQuestionCard";
import GwJourneyBundleEditDialog from "@/components/ghostwriter/journeys/GwJourneyBundleEditDialog";

export default {
  name: "GwJourneyBundles",
  components: {
    GwJourneyNuggetCard,
    GwJourneyQuestionCard,
    GwJourneyBundleEditDialog,
  },
  data() {
    return {
      openedPanels: [],
    };
  },
  computed: {
    ...mapGetters("ghostwriter", {
      journey: "get_gw_journey",
      source_filter: "get_source_filter",
    }),
    visibleBundles() {
      var bundles = { ...this.journey }.bundles;
      return bundles;
    },
  },
  watch: {
    "source_filter.expand_all_bundles": {
      handler: function (v) {
        if (!v) {
          this.openedPanels = [];
        } else {
          this.openedPanels = [...this.visibleBundles].map((b, i) => {
            return i;
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    getBundleNuggets(bundle) {
      var nuggets = [];
      bundle.nuggets.forEach((n) => {
        if (
          !(this.source_filter.hide_accepted && n.is_accepted) &&
          !(this.source_filter.hide_deleted && n.is_deleted) &&
          !!this.source_filter.show_nuggets
        ) {
          n.bundle = bundle.title;
          nuggets.push(n);
        }
      });
      return nuggets;
    },
    getBundleQuestions(bundle) {
      var questions = [];
      bundle.questions.forEach((q) => {
        if (
          !(this.source_filter.hide_accepted && q.is_accepted) &&
          !(this.source_filter.hide_deleted && q.is_deleted) &&
          !!this.source_filter.show_questions
        ) {
          q.bundle = bundle.title;
          questions.push(q);
        }
      });
      return questions;
    },
    allContentChecked(b, type) {
      var checked = true;
      if (!type || type === "nuggets") {
        b.nuggets.forEach((n) => {
          if (!n.is_accepted && !n.is_deleted) checked = false;
        });
      }
      if (!type || type === "questions") {
        b.questions.forEach((q) => {
          if (!q.is_accepted && !q.is_deleted) checked = false;
        });
      }
      return checked;
    },
    uncheckedContentLength(arr) {
      if (!arr || arr.length === 0) return 0;
      var unchecked_content = arr.filter(
        (c) => !c.is_deleted && !c.is_accepted
      );
      return unchecked_content.length;
    },
    previewImages(bundle) {
      if (!bundle.images) return [];
      var images = [];
      Object.keys(bundle.images).forEach((k) =>
        images.push({ id: k, image: bundle.images[k] })
      );
      return images;
    },
  },
};
</script>
