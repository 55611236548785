<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog v-model="dialog" max-width="640" persistent scrollable>
      <v-card color="grey lighten-3" :loading="loading">
        <v-card-title class="overline">
          <span>{{ $t("ng.manage_nuggets") }}</span>
        </v-card-title>
        <v-card-text v-if="loading">
          <p class="pt-4">
            {{ $t("ng.please_wait") }}
          </p>
        </v-card-text>

        <v-card-text v-if="!loading">
          <div class="pt-4">
            <h5 class="caption">
              {{ $t("resources.journeys.nuggetSelector.selectedNuggets") }}
            </h5>
            <v-row v-if="selected.length > 0" dense class="mb-4">
              <v-col v-for="(nugget, i) in selected" :key="i" cols="12">
                <v-list-item class="white" outlined dense>
                  <v-list-item-content>
                    <v-list-item-title>{{ nugget.title }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <span>{{ nugget.category.name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="d-flex flex-row">
                    <NuggetPreviewDialog :nugget="nugget">
                      <template #action="{ openDialog }">
                        <v-btn
                          icon
                          small
                          color="primary"
                          :disabled="loading"
                          @click="openDialog()"
                        >
                          <v-icon small>mdi-eye-circle</v-icon>
                        </v-btn>
                      </template>
                    </NuggetPreviewDialog>
                    <v-btn
                      small
                      icon
                      color="error"
                      @click="removeNugget(nugget)"
                    >
                      <v-icon small> mdi-minus-circle </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
            </v-row>
            <p v-else class="font-weight-bold">
              {{ $t("resources.journeys.nuggetSelector.noNuggetsYet") }}
            </p>
          </div>

          <div class="pt-4">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="search"
                  clearable
                  hide-details
                  outlined
                  background-color="white"
                  dense
                  :label="$t('general.search')"
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <v-autocomplete
                  v-model="category"
                  :items="visibleCategories"
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  background-color="white"
                  hide-details
                  chips
                  :label="$t('resources.categories.singular')"
                  small-chips
                  deletable-chips
                  deletable
                >
                  <template #item="{ item }">
                    {{ item.name }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <v-btn
                  color="primary"
                  small
                  depressed
                  width="100%"
                  :disabled="loading"
                  @click="searchNuggets()"
                >
                  {{ $t("general.search") }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card v-if="nuggets" outlined>
                  <v-data-table
                    :headers="tableHeaders"
                    :items="visibleNuggets"
                    :items-per-page="5"
                    dense
                    show-expand
                    single-expand
                    item-key="id"
                    :expanded.sync="expanded"
                  >
                    <template #item="{ item, expand, isExpanded }">
                      <tr>
                        <td>
                          <v-tooltip v-if="!!isUsedInJourney(item)" right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                v-bind="attrs"
                                small
                                v-on="on"
                              >
                                mdi-alert
                              </v-icon>
                            </template>
                            <span>{{ $t("ng.nugget_used_in_journey") }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-btn
                            v-if="!!nuggetIsSelected(item)"
                            icon
                            small
                            color="error"
                            @click="removeNugget(item)"
                          >
                            <v-icon small> mdi-minus-circle </v-icon>
                          </v-btn>
                          <v-btn
                            v-if="!nuggetIsSelected(item)"
                            icon
                            small
                            color="success"
                            @click="addNugget(item)"
                          >
                            <v-icon small> mdi-plus-circle </v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <span class="caption">{{ item.title }}</span>
                        </td>
                        <td>
                          <span class="caption">{{ item.category.name }}</span>
                        </td>
                        <td>
                          <v-btn icon small @click="expand(!isExpanded)">
                            <v-icon small>
                              {{
                                isExpanded
                                  ? "mdi-chevron-up"
                                  : "mdi-chevron-down"
                              }}
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template #expanded-item="{ headers, item }">
                      <td :colspan="headers.length" class="pa-4">
                        <v-row>
                          <v-col cols="6" class="py-0">
                            <h5 class="mt-2">
                              {{ $t("resources.nuggets.type") }}
                            </h5>
                            <div class="caption">
                              {{
                                $t("resources.nuggets.types." + item.ng_format)
                              }}
                            </div>
                          </v-col>
                          <v-col cols="6" class="py-0 text-right">
                            <v-btn
                              x-small
                              color="primary"
                              class="mt-2"
                              text
                              :to="{
                                name: 'NuggetDetail',
                                params: { id: item.id },
                              }"
                              target="_blank"
                            >
                              {{ $t("general.details") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="py-0">
                            <h5 class="mt-2">
                              {{
                                $t("resources.journeys.propNames.description")
                              }}
                            </h5>
                            <div class="caption">
                              {{ item.description }}
                            </div>
                          </v-col>
                        </v-row>
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>

            <p
              v-if="!nuggets || nuggets.length == 0"
              class="caption text-center"
            >
              <span v-if="!nuggets">{{
                $t("resources.journeys.noSearch")
              }}</span>
            </p>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="error" small text class="mr-2" @click="closeDialog()">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            small
            text
            :disabled="selected.length == 0"
            @click="addNuggets()"
          >
            {{ $t("general.apply") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Nugget } from "@/models/Nugget.js";
import NuggetPreviewDialog from "@/components/nuggets/NuggetPreviewDialog";

export default {
  name: "NuggetSelector",
  components: { NuggetPreviewDialog },
  props: {
    alreadyInUse: {
      type: Array,
      required: false,
      default: () => [],
    },
    bundle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      category: 0,
      selected: [],
      idsToHide: [],
      tableHeaders: [
        { text: "", width: "10px", sortable: false },
        { text: "", width: "10px", sortable: false },
        { text: this.$t("resources.nuggets.title"), value: "title" },
        { text: this.$t("resources.nuggets.category"), value: "category.name" },
        { text: "", value: "data-table-expand" },
      ],
      expanded: [],
      loading: false,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      nuggets: "get_journey_nuggets",
      journey: "get_journey",
      bundles: "get_journey_bundles",
      language: "get_selected_language",
    }),
    ...mapGetters("categories", {
      categories: "get_visible_categories",
    }),
    visibleNuggets() {
      let nuggets = [...this.nuggets];
      return nuggets;
    },
    visibleCategories() {
      if (!this.categories) return [];
      return this.categories
        .filter((category) => {
          return category.stats.nuggets.count > 0;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  /* watch: {
    showDialog: {
      handler(v) {
        if (v) this.initSelectedNuggets();
      },
    },
  }, */
  mounted() {
    if (this.alreadyInUse) {
      this.alreadyInUse.forEach((step) => {
        this.idsToHide.push(step.nugget.id);
      });
    }
  },
  methods: {
    ...mapActions("journeys", ["fetch_journey_nuggets"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("journeys", ["set_journey_nuggets"]),
    ...mapMutations("categories", ["set_categories"]),
    async searchNuggets() {
      this.loading = true;
      let payload = {
        search: this.search,
        category_id: this.category,
        language: this.$i18n.locale,
      };
      await this.fetch_journey_nuggets({
        payload: payload,
      });
      this.loading = false;
    },
    openDialog() {
      this.initSelectedNuggets();
      this.dialog = true;
    },
    closeDialog(setData) {
      var selected = [...this.selected].filter((n) => !this.isUsedInJourney(n));
      this.$emit("close", [setData ? selected : null, this.bundle]);
      this.set_categories(null);
      this.selected = [];
      this.set_journey_nuggets(null);
      this.category = 0;
      this.dialog = false;
    },
    addNugget(nugget) {
      if (this.selected.indexOf(nugget) === -1) this.selected.push(nugget);
    },
    removeNugget(nugget) {
      let selected = this.selected;
      this.selected = selected.filter(function (selectedNugget) {
        return selectedNugget.id !== nugget.id;
      });
    },
    nuggetIsSelected(nugget) {
      return (
        this.selected.filter((selectedNugget) => {
          return selectedNugget.id === nugget.id;
        }).length > 0
      );
    },
    async addNuggets() {
      this.closeDialog(true);
    },
    isUsedInJourney(nugget) {
      var isUsed = false;
      var bundles = [...this.bundles];
      bundles.forEach((bundle) => {
        bundle.steps.forEach((step) => {
          if (
            step.type === "nugget" &&
            step.nugget &&
            step.nugget.id === nugget.id
          )
            isUsed = true;
        });
      });
      return isUsed;
    },
    async initSelectedNuggets() {
      this.loading = true;
      await this.fetch_categories();
      let payload = {
        search: "",
        category_id: null,
        language: this.language,
      };
      await this.fetch_journey_nuggets({
        payload: payload,
      });
      this.alreadyInUse.forEach((step) => {
        if (step.type !== "nugget") return false;
        let nugget = new Nugget(step.nugget);
        nugget.category = this.categories.filter(
          (c) => c.id === nugget.category.id
        )[0];
        this.selected.push(nugget);
      });
      this.loading = false;
    },
  },
};
</script>

<style></style>
