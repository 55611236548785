<template>
  <div>
    <section>
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.editBundles.title')"
        :subtitle="$t('ng.journey.edit_bundle_subtitle')"
      >
        <template #action>
          <LanguageChooserInline
            hidedetails
            small
            :initial="language"
            @change="changeLanguage"
          />
        </template>
      </SectionHeader>

      <v-container>
        <v-row>
          <v-expand-transition>
            <v-col
              v-if="['unpublished', 'published'].includes(journey.status)"
              cols="12"
            >
              <JourneyStepper :journey="journey" />
            </v-col>
          </v-expand-transition>
        </v-row>
      </v-container>

      <v-container ref="viewcontainer">
        <v-card outlined :loading="loading">
          <v-card-title class="secondary--text">{{
            $t("general.edit")
          }}</v-card-title>
          <v-card-subtitle>{{
            $t("ng.journey.edit_bundle_comment")
          }}</v-card-subtitle>
          <v-card-text>
            <v-alert v-if="journey.isSkippingLevels" type="error" color="error">
              {{ $t("ng.journey.level_error_hint") }}
            </v-alert>
            <v-alert v-if="journey.status !== 'unpublished'" text color="info">
              {{ $t("resources.journeys.editWarning") }}
            </v-alert>
            <v-list-group
              v-for="(b, i) in sortedBundles"
              :key="`bundle-${b.id}`"
              :value="false"
              class="grey lighten-3 rounded bundle"
              active-class="black--text"
              :class="[
                !!sortedBundles[i - 1] &&
                sortedBundles[i].level > sortedBundles[i - 1].level
                  ? 'mt-10'
                  : 'mt-2',
                { invalid: !b.steps || b.steps.length === 0 },
              ]"
              style="
                border: 1px solid rgba(0, 0, 0, 0.12) !important;
                transition: 0.2s ease;
              "
            >
              <template v-slot:activator>
                <v-list-item-action class="mr-4">
                  <div class="d-flex align-center">
                    <v-btn
                      icon
                      small
                      :disabled="
                        b.level === 1 ||
                        journey.status !== 'unpublished' ||
                        loading ||
                        !['editor', 'admin', 'superadmin'].includes(role)
                      "
                      @click.stop="moveBundle('up', b)"
                    >
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-tooltip right>
                      <template #activator="{ on, attrs }">
                        <v-avatar
                          :color="
                            !levelsValid
                              ? 'error'
                              : !!bundlesMoved
                              ? 'primary'
                              : 'grey lighten-2'
                          "
                          size="26"
                          class="mx-1"
                          :class="
                            !levelsValid || !!bundlesMoved
                              ? 'white--text'
                              : 'secondary--text'
                          "
                          style="transition: 0.2s"
                          rounded
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span class="caption">{{ b.level }}</span>
                        </v-avatar>
                      </template>
                      <span>{{ $t("resources.journeys.level") }}</span>
                    </v-tooltip>
                    <v-btn
                      icon
                      small
                      :disabled="
                        b.level >= maxLevel + 1 ||
                        journey.status !== 'unpublished' ||
                        loading ||
                        !['editor', 'admin', 'superadmin'].includes(role)
                      "
                      @click.stop="moveBundle('down', b)"
                    >
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
                <v-list-item-avatar rounded class="mr-4">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-img :src="b.getImage()" v-on="on" v-bind="attrs" />
                    </template>
                    <div>
                      <h3 class="mb-2">{{ b.name }}</h3>
                      <p>
                        {{ $t("resources.journeys.propNames.description") }}:
                        <br />
                        {{ b.description }}
                      </p>
                    </div>
                  </v-tooltip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="d-flex">
                    <div>{{ b.name }}</div>
                  </v-list-item-title>
                  <v-list-item-subtitle class="d-flex align-center">
                    <v-tooltip right>
                      <template #activator="{ on, attrs }">
                        <v-chip
                          label
                          small
                          outlined
                          class="justify-center"
                          style="min-width: 90px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon left small color="secondary"
                            >mdi-school</v-icon
                          >
                          <span>{{ getBundleNuggetCount(b) }}</span>
                        </v-chip>
                      </template>
                      <span>{{
                        $t("ng.journey.bundle_nugget_count", {
                          count: getBundleNuggetCount(b),
                        })
                      }}</span>
                    </v-tooltip>

                    <v-tooltip right>
                      <template #activator="{ on, attrs }">
                        <v-chip
                          label
                          small
                          outlined
                          class="justify-center ml-4"
                          style="min-width: 90px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon left small color="secondary"
                            >mdi-lightbulb</v-icon
                          >
                          <span
                            >{{ getBundleQuizCount(b) }} ({{
                              b.quiz_criteria
                            }}%)</span
                          >
                        </v-chip>
                      </template>
                      <span>{{
                        $t("ng.journey.bundle_quiz_count", {
                          count: getBundleQuizCount(b),
                          criteria: b.quiz_criteria,
                        })
                      }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <div class="d-flex align-center">
                  <EditBundleDialog :bundle="b">
                    <template #button="{ openDialog }">
                      <v-btn
                        small
                        icon
                        color="secondary"
                        class="mr-2"
                        :disabled="
                          loading ||
                          bundlesMoved ||
                          journey.status !== 'unpublished' ||
                          !['editor', 'admin', 'superadmin'].includes(role)
                        "
                        @click.stop="openDialog()"
                      >
                        <v-icon>mdi-pencil-circle</v-icon>
                      </v-btn>
                    </template>
                  </EditBundleDialog>
                  <JourneybundleDialogDelete :bundle="b" :journey="journey">
                    <template #button="{ openDialog }">
                      <v-btn
                        small
                        icon
                        color="error"
                        :disabled="
                          loading ||
                          bundlesMoved ||
                          journey.status !== 'unpublished' ||
                          !['editor', 'admin', 'superadmin'].includes(role)
                        "
                        @click.stop="openDialog()"
                      >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </template>
                  </JourneybundleDialogDelete>
                </div>
              </template>

              <v-list-item
                v-for="(s, i) in orderedSteps(b.steps)"
                :key="`step-${s.id}`"
                class="white"
                :style="
                  i !== orderedSteps(b.steps).length - 1
                    ? 'border-bottom: 1px solid rgba(0,0,0,.12) !important'
                    : ''
                "
              >
                <v-list-item-action
                  class="d-flex flex-row align-center justify-center ml-3 mr-6"
                >
                  <v-btn
                    icon
                    x-small
                    :disabled="
                      s.order === 1 ||
                      journey.status !== 'unpublished' ||
                      bundlesMoved ||
                      !['editor', 'admin', 'superadmin'].includes(role)
                    "
                    @click="moveStep(s, b, 'up')"
                  >
                    <v-icon small>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-avatar
                    :color="'transparent'"
                    size="20"
                    class="mx-1"
                    rounded
                  >
                    <span class="caption">{{ s.order }}</span>
                  </v-avatar>
                  <v-btn
                    icon
                    x-small
                    :disabled="
                      s.order === b.steps.length ||
                      journey.status !== 'unpublished' ||
                      bundlesMoved ||
                      !['editor', 'admin', 'superadmin'].includes(role)
                    "
                    @click="moveStep(s, b, 'down')"
                  >
                    <v-icon small>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-avatar color="grey lighten-3" rounded class="mr-2">
                  <v-tooltip right>
                    <template #activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">
                        {{
                          s.type === "nugget" ? "mdi-school" : "mdi-lightbulb"
                        }}
                      </v-icon>
                    </template>
                    <span>{{
                      s.type === "nugget"
                        ? $t("resources.nuggets.singular")
                        : $t("resources.journeys.stepTypes.quiz.singular")
                    }}</span>
                  </v-tooltip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    s.type === "nugget"
                      ? s.nugget.title
                      : `${s.quiz.question_count} ${
                          s.quiz.question_count === 1
                            ? $t("resources.questions.singular")
                            : $t("resources.questions.plural")
                        }`
                  }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action class="d-flex flex-row">
                  <NuggetPreviewDialog
                    v-if="s.type === 'nugget'"
                    :nugget="s.nugget"
                  >
                    <template #action="{ openDialog }">
                      <v-btn
                        icon
                        small
                        color="primary"
                        :disabled="
                          loading ||
                          !['editor', 'admin', 'superadmin'].includes(role)
                        "
                        @click="openDialog()"
                      >
                        <v-icon>mdi-eye-circle</v-icon>
                      </v-btn>
                    </template>
                  </NuggetPreviewDialog>

                  <QuizCreator
                    v-if="s.type === 'quiz'"
                    :quiz="s"
                    :bundle="b"
                    @close="closeQuizDialog"
                  >
                    <template #button="{ openDialog }">
                      <v-btn
                        icon
                        small
                        color="secondary"
                        class="ml-2"
                        :disabled="
                          loading ||
                          bundlesMoved ||
                          journey.status !== 'unpublished' ||
                          !['editor', 'admin', 'superadmin'].includes(role)
                        "
                        @click="openDialog()"
                      >
                        <v-icon>mdi-pencil-circle</v-icon>
                      </v-btn>
                    </template>
                  </QuizCreator>
                  <v-btn
                    icon
                    small
                    color="red"
                    :disabled="
                      loading ||
                      bundlesMoved ||
                      journey.status !== 'unpublished' ||
                      !['editor', 'admin', 'superadmin'].includes(role)
                    "
                    @click="removeStep(s, b)"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-list-item
                v-if="!b.steps || b.steps.length === 0"
                class="white"
              >
                <v-list-item-content class="justify-center">{{
                  $t("ng.journey.empty_bundle_hint")
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item class="d-flex justify-center rounded">
                <NuggetSelector
                  :already-in-use="bundleNuggets(b)"
                  :bundle="b"
                  @close="closeNuggetDialog"
                >
                  <template #button="{ openDialog }">
                    <v-btn
                      color="primary"
                      depressed
                      small
                      :disabled="
                        loading ||
                        bundlesMoved ||
                        journey.status !== 'unpublished' ||
                        !['editor', 'admin', 'superadmin'].includes(role)
                      "
                      @click.stop="openDialog()"
                    >
                      <v-icon small>mdi-plus-circle</v-icon>
                      <span class="mx-2">{{
                        $t("resources.journeys.addNuggets")
                      }}</span>
                      <v-icon small>mdi-school</v-icon>
                    </v-btn>
                  </template>
                </NuggetSelector>
                <QuizCreator :bundle="b" @close="closeQuizDialog" class="ml-4">
                  <template #button="{ openDialog }">
                    <v-btn
                      color="primary"
                      depressed
                      small
                      :disabled="
                        loading ||
                        bundlesMoved ||
                        journey.status !== 'unpublished' ||
                        !['editor', 'admin', 'superadmin'].includes(role)
                      "
                      @click.stop="openDialog()"
                    >
                      <v-icon small>mdi-plus-circle</v-icon>
                      <span class="mx-2">{{
                        $t("resources.journeys.addQuiz")
                      }}</span>
                      <v-icon small>mdi-lightbulb</v-icon>
                    </v-btn>
                  </template>
                </QuizCreator>
              </v-list-item>
            </v-list-group>

            <v-expand-transition>
              <div v-if="!bundlesMoved">
                <v-list-item
                  class="d-flex justify-center grey lighten-3 mt-8 rounded"
                  style="border: 1px solid rgba(0, 0, 0, 0.12) !important"
                  three-line
                >
                  <AddBundleDialog>
                    <template #button="{ openDialog }">
                      <v-btn
                        block
                        depressed
                        color="primary"
                        class="ml-4 mr-2"
                        :disabled="
                          loading ||
                          bundlesMoved ||
                          journey.status !== 'unpublished'
                        "
                        @click="openDialog()"
                      >
                        <v-icon>mdi-plus-circle</v-icon>
                        <span class="ml-2">{{
                          $t("ng.journey.add_bundle")
                        }}</span>
                      </v-btn>
                    </template>
                  </AddBundleDialog>
                </v-list-item>
              </div>
            </v-expand-transition>
          </v-card-text>
        </v-card>
      </v-container>
    </section>

    <v-bottom-sheet
      :value="bundlesMoved"
      ref="sheet"
      inset
      hide-overlay
      persistent
      no-click-animation
      :retain-focus="false"
      max-width="1024"
    >
      <v-card color="primary" dark>
        <v-card-title class="overline">{{
          $t("resources.journeys.moveSave")
        }}</v-card-title>
        <v-card-text>
          <p class="mb-0">
            {{ $t("ng.journey.level_change_hint") }}
          </p>

          <v-expand-transition leave-absolute>
            <div v-if="!levelsValid">
              <v-alert type="error" class="mt-4" dense>{{
                $t("ng.journey.level_error_hint_2")
              }}</v-alert>
            </div>
          </v-expand-transition>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            small
            :disabled="
              !bundlesMoved || loading || journey.status !== 'unpublished'
            "
            @click="resetOrder()"
            >{{ $t("general.reset") }}</v-btn
          >
          <v-spacer />
          <v-btn
            text
            small
            :disabled="
              !bundlesMoved ||
              !levelsValid ||
              loading ||
              journey.status !== 'unpublished'
            "
            @click="moveBundles()"
            >{{ $t("ng.journey.change_level") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";
import JourneybundleDialogDelete from "@/components/journeys/dialogs/JourneybundleDialogDelete";
import AddBundleDialog from "@/components/journeys/dialogs/AddBundleDialog";
import EditBundleDialog from "@/components/journeys/dialogs/EditBundleDialog";
import NuggetSelector from "@/components/journeys/NuggetSelector";
import QuizCreator from "@/components/journeys/QuizCreator";
import NuggetPreviewDialog from "@/components/nuggets/NuggetPreviewDialog";
import JourneyStepper from "@/components/journeys/JourneyStepper";

export default {
  name: "JourneyBundles",
  components: {
    SectionHeader,
    LanguageChooserInline,
    JourneybundleDialogDelete,
    AddBundleDialog,
    EditBundleDialog,
    NuggetSelector,
    QuizCreator,
    NuggetPreviewDialog,
    JourneyStepper,
  },
  data() {
    return {
      loading: false,
      originalBundles: null,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      bundles: "get_journey_bundles",
      language: "get_selected_language",
    }),
    sortedBundles() {
      if (!this.bundles) return [];
      return [...this.bundles].sort((a, b) => a.level - b.level);
    },
    levelsValid() {
      var bundles = [...this.bundles].sort((a, b) => a.level - b.level);
      var valid = true;
      var lastLevel = 0;
      bundles.forEach((b) => {
        if (b.level - lastLevel > 1) valid = false;
        lastLevel = b.level;
      });
      return valid;
    },
    maxLevel() {
      var level = [...this.journey.bundles].sort((a, b) => a.level - b.level)[
        this.bundles.length - 1
      ].level;
      return level;
    },
    bundlesMoved() {
      if (
        !this.originalBundles ||
        this.originalBundles.length !== this.bundles.length
      )
        return false;
      var bundles = [...this.bundles].sort((a, b) => a.level - b.level);
      var ori = [...this.originalBundles].sort((a, b) => a.level - b.level);
      var moved = false;
      bundles.forEach((bundle, i) => {
        if (bundle.id !== ori[i].id || bundle.level !== ori[i].level)
          moved = true;
      });
      return moved;
    },
  },
  watch: {
    journey: {
      handler: function () {
        this.originalBundles = JSON.parse(JSON.stringify([...this.bundles]));
      },
      deep: true,
    },
    bundlesMoved(v) {
      this.$nextTick(() => {
        var el = this.$refs.viewcontainer;
        v
          ? el.classList.add("bottom-margin")
          : el.classList.remove("bottom-margin");
        this.$refs.sheet.showScroll();
      });
    },
  },
  mounted() {
    this.originalBundles = JSON.parse(JSON.stringify([...this.bundles]));
  },
  methods: {
    ...mapActions("journeys", [
      "add_bundle",
      "move_bundles",
      "fetch_journey",
      "add_bundle_steps",
      "delete_bundle_step",
      "patch_bundle_step",
      "move_bundle_steps",
    ]),
    ...mapMutations("journeys", [
      "set_journey_bundles",
      "set_selected_language",
    ]),
    moveBundle(direction, bundle) {
      if (direction === "up") bundle.level--;
      if (direction === "down") bundle.level++;
    },
    getBundleNuggetCount(bundle) {
      var steps = [...bundle.steps];
      return steps.filter((s) => s.type === "nugget").length;
    },
    getBundleQuizCount(bundle) {
      var steps = [...bundle.steps];
      return steps.filter((s) => s.type === "quiz").length;
    },
    async moveBundles() {
      this.loading = true;
      var bundles = [...this.bundles];
      var newOrder = [];
      bundles.forEach((b) => newOrder.push({ id: b.id, level: b.level }));
      var payload = {
        journey_id: this.journey.id,
        bundles: newOrder,
      };
      var res = await this.move_bundles({
        payload: payload,
      });
      this.loading = false;
      this.originalBundles = JSON.parse(JSON.stringify([...this.bundles]));

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.journeys.moveSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("resources.journeys.moveError"),
        });
      }
    },
    changeLanguage(lang) {
      this.set_selected_language(lang);
    },
    async resetOrder() {
      this.loading = true;
      await this.fetch_journey({
        id: this.journey.id,
        language: this.language,
      });
      this.loading = false;
    },
    bundleNuggets(bundle) {
      var steps = [...bundle.steps];
      return steps.filter((s) => s.type === "nugget");
    },
    closeNuggetDialog(data) {
      if (!data[0]) return false;
      if (data && data.length > 0) this.addSteps(data[0], "nuggets", data[1]);
    },
    closeQuizDialog(data) {
      if (!data[0]) return false;
      if (data && data[2]) this.updateQuiz(data[2], data[0], data[1]);
      if (!data[2] && data && data[0].length > 0)
        this.addSteps(data[0], "quiz", data[1]);
    },
    async addSteps(steps, type, bundle) {
      this.loading = true;
      let apiSteps = [];
      let payload = { bundle_id: bundle.id, journey_id: this.journey.id };
      let order = this.editableQuiz
        ? bundle.steps.length
        : bundle.steps.length + 1;
      steps.forEach((step) => {
        let apiStep = { order: order };
        if (type == "nuggets") {
          apiStep.type_id = 1;
          apiStep.nugget_id = step.id;
        }
        if (type == "quiz") {
          apiStep.type_id = 2;
          apiStep.question_id = step.id;
        }
        apiSteps.push(apiStep);
        if (type == "nuggets") order++;
      });
      payload.steps = apiSteps;

      let res = await this.add_bundle_steps({
        payload: payload,
      });
      this.loading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("general.errors.create_failed"),
        });
      }
    },
    async removeStep(step, bundle) {
      this.loading = true;
      let payload = {
        bundle_id: bundle.id,
        journey_id: this.journey.id,
        step_id: step.id,
      };
      let res = await this.delete_bundle_step({
        payload: payload,
      });
      this.loading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    async updateQuiz(step, questions, bundle) {
      this.loading = true;
      let question_ids = [];
      questions.forEach((question) => question_ids.push(question.id));
      let payload = {
        bundle_id: bundle.id,
        journey_id: this.journey.id,
        step_id: step.id,
        question_ids: question_ids,
      };
      let res = await this.patch_bundle_step({
        payload: payload,
      });
      this.loading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    orderedSteps(steps) {
      return [...steps].sort((a, b) => a.order - b.order);
    },
    moveStep(step, bundle, direction) {
      var steps = [...bundle.steps];
      var position = step.order;
      var other_step = null;
      if (direction === "up") {
        other_step = steps.filter((s) => s.order === position - 1)[0];
        other_step.order++;
        step.order--;
      }
      if (direction === "down") {
        other_step = steps.filter((s) => s.order === position + 1)[0];
        other_step.order--;
        step.order++;
      }
      this.moveSteps(bundle);
    },
    async moveSteps(bundle) {
      this.loading = true;
      var steps = { ...bundle }.steps.sort((a, b) => a.order - b.order);
      var apiSteps = [];
      steps.forEach((s, i) => {
        apiSteps.push({ id: s.id, order: i + 1 });
      });
      let payload = {
        bundle_id: bundle.id,
        journey_id: this.journey.id,
        steps: apiSteps,
      };
      let res = await this.move_bundle_steps({
        payload: payload,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.journeys.moveSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("resources.journeys.moveError"),
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.bottom-margin {
  margin-bottom: 240px;
}
.bundle {
  position: relative;
  margin-left: 12px;
}
.bundle::before {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: calc(100% + 10px);
  margin-top: -5px;
  margin-left: -10px;
  background-color: var(--v-primary-base) !important;
  opacity: 0.65;
}
.bundle.invalid::before {
  background-color: var(--v-error-base) !important;
}
</style>
