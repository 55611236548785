<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('navigation.journeys.title')"
      icon="mdi-map-marker-path"
      ><template #titleButton>
        <GwCreditInfoMenu />
      </template>
      <template #button>
        <FileUploadDialog>
          <template #action="{ openDialog }">
            <v-btn
              color="primary"
              fab
              absolute
              right
              bottom
              dark
              small
              @click="openDialog()"
            >
              <v-icon>mdi-arrow-up-bold</v-icon>
            </v-btn>
          </template>
        </FileUploadDialog>
      </template>
      <template #extension>
        <ViewNavigation :nav-items="navItems" only-tabs class="flex-grow-1" />
      </template>
    </ViewHeader>

    <section>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <router-view :key="$route.fullPath" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import ViewHeader from "@/components/_layout/ViewHeader";
import ViewNavigation from "@/components/_layout/ViewNavigation";
import FileUploadDialog from "@/components/global/FileUploadDialog";
import GwCreditInfoMenu from "@/components/ghostwriter/GwCreditInfoMenu";

export default {
  name: "TmpOverview",
  components: {
    ViewHeader,
    ViewNavigation,
    FileUploadDialog,
    GwCreditInfoMenu,
  },
  data() {
    return {
      openSpeedDial: false,
    };
  },
  computed: {
    navItems() {
      let items = [{ title: "Files", link: { name: "FileOverview" } }];
      return items;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: "tmp",
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin"]);
  },
};
</script>
