<template>
  <div>
    <v-tooltip top color="primary" :disabled="!disabled">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn
            color="primary"
            x-small
            text
            :disabled="disabled"
            @click="showDialog = true"
          >
            {{ $t("general.edit") }}
          </v-btn>
        </div>
      </template>
      <span>{{
        !disabled
          ? $t("resources.questions.activationHint_cannotBeEdited")
          : $t("resources.questions.activationHint_unsaved")
      }}</span>
    </v-tooltip>

    <v-dialog v-model="showDialog" max-width="640">
      <v-card color="grey lighten-4">
        <v-card-title class="secondary--text overline">
          {{ $t("ng.edit_categories") }}
        </v-card-title>
        <ViewLoader v-if="loading" />
        <v-card-text v-if="!loading" class="py-2">
          <CategorySelector
            :input-value="[...question.category_ids]"
            multiple
            @change="setCategory"
          />
          <!-- <v-autocomplete
            v-model="selectedCategories"
            :items="sortedCategories"
            item-value="id"
            item-text="name"
            multiple
            filled
            dense
            hide-details
            chips
            outlined
            background-color="white"
            class="mb-4"
            small-chips
            deletable-chips
          >
            <template #item="{ item }">
              <v-list-item-action>
                <v-checkbox :value="selectedCategories.includes(item.id)" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip v-if="item.groups.length === 0" x-small label>
                    {{ $t("ng.no_category_group") }}
                  </v-chip>
                  <v-chip
                    v-for="group in item.groups"
                    :key="group.id"
                    x-small
                    label
                    class="mr-2"
                  >
                    {{ group.name }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete> -->

          <div v-if="notMyCategories && notMyCategories.length > 0">
            <div class="caption">
              {{ $t("gw.notMyCategories") }}
            </div>
            <div>
              <v-chip-group column>
                <v-chip
                  v-for="(c, i) in notMyCategories"
                  :key="i"
                  label
                  small
                  color="primary"
                >
                  {{ c.name }}
                </v-chip>
              </v-chip-group>
            </div>
          </div>

          <v-expand-transition>
            <v-alert
              v-show="selectedCategories.length === 0 && role === 'editor'"
              type="error"
              border="left"
              text
              dense
            >
              <span class="caption" v-html="$t('gw.noCategoryForEditorHint')" />
            </v-alert>
          </v-expand-transition>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small text color="secondary" @click="showDialog = false">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            small
            text
            color="success"
            :disabled="!inputChanged"
            :loading="loading"
            @click="changeCategories()"
          >
            {{ $t("general.apply") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import CategorySelector from "@/components/global/CategorySelector";

export default {
  name: "QuestionCategoryChanger",
  components: {
    ViewLoader,
    CategorySelector,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      selectedCategories: [],
      loading: false,
    };
  },
  computed: {
    inputChanged() {
      let changed = false;
      if (
        this.selectedCategories.length + this.notMyCategories.length !==
        this.question.categories.length
      )
        changed = true;
      this.question.categories.forEach((c) => {
        if (!this.selectedCategories.includes(c.id)) changed = true;
      });
      return changed;
    },
    notMyCategories() {
      if (this.role !== "editor") return [];
      let categories = [...this.question.categories];
      return categories.filter(
        (c) => !this.auth.related_categories.includes(c.id)
      );
    },
  },
  methods: {
    ...mapActions("questions", ["patch_question"]),
    /* resetCategories() {
      let categories = [];
      this.question.categories.forEach((cat) => {
        if (
          this.auth.related_categories.includes(cat.id) ||
          this.role !== "editor"
        ) {
          categories.push(cat.id);
        }
      });
      this.selectedCategories = categories;
    }, */
    async changeCategories() {
      let categories = [...this.selectedCategories];
      this.notMyCategories.forEach((c) => categories.push(c.id));

      this.loading = true;
      let properties = [{ name: "category_ids", value: categories }];

      let payload = {
        id: this.question.id,
        properties: properties,
        language: this.$i18n.locale,
      };

      let res = await this.patch_question({
        payload: payload,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        this.showDialog = false;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    setCategory(v) {
      this.selectedCategories = v;
    },
  },
};
</script>
