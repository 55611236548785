<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      @click:outside="closeDialog()"
    >
      <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
        <v-card-title class="overline secondary--text pb-0">
          {{ $t("gw.addSession.newSession") }}
        </v-card-title>
        <v-card-text>
          <div class="caption mb-4">
            {{ $t("gw.addSession.hint") }}
          </div>
          <v-row>
            <v-col cols="12">
              <InputTextfield
                v-model="name"
                :label="$t('resources.categories.name')"
                dense
                hide-details
                :rules="rules.name"
                listen-to-input
              />
            </v-col>
            <v-col cols="12">
              <LanguageChooserInline
                colored
                dense
                class="pb-0"
                hidedetails
                :initial="$i18n.locale"
                @change="changeLanguage"
              />
            </v-col>
            <v-col cols="12" class="mt-1 pb-0">
              <span class="caption">{{ $t("gw.addSession.optionsHint") }}</span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-model="options.nuggets"
                dense
                inset
                hide-details
                color="secondary"
                class="ml-1 mt-0"
              >
                <template #label>
                  {{ $t("gw.addSession.createNuggets") }}
                </template>
              </v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-model="options.questions"
                dense
                inset
                hide-details
                color="secondary"
                class="ml-1 mt-0"
              >
                <template #label>
                  {{ $t("gw.addSession.createQuestions") }}
                </template>
              </v-switch>
            </v-col>
            <v-col cols="12" class="pb-0">
              <span class="caption">{{
                $t("gw.addSession.defaultsHint")
              }}</span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-slider
                v-model="defaults.time"
                :label="$t('ng.question_time')"
                thumb-label="always"
                min="0"
                max="180"
                color="secondary"
                class="pt-1"
                track-color="secondary lighten-3"
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6">
              <CategorySelector
                :label="$t('gw.default_category')"
                @change="setCategory"
              />
              <!-- <v-autocomplete
                v-model="defaults.category_id"
                :label="$t('gw.default_category')"
                outlined
                :items="sortedCategories"
                :loading="!categories"
                :disabled="!categories"
                hide-details
                item-text="name"
                item-value="id"
                background-color="white"
                dense
              /> -->
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-model="defaults.activate"
                dense
                inset
                hide-details
                color="secondary"
                class="ml-1 mt-0"
              >
                <template #label>
                  {{ $t("gw.activateContent") }}
                </template>
              </v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small color="secondary" @click="closeDialog()">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            text
            color="success"
            small
            :loading="loading"
            :disabled="name.length === 0 || loading"
            @click="addSession()"
          >
            {{ $t("gw.create") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LanguageChooserInline from "@/components/global/LanguageChooserInline";
import CategorySelector from "@/components/global/CategorySelector";

export default {
  name: "GwAddSessionDialog",
  components: {
    LanguageChooserInline,
    CategorySelector,
  },
  data() {
    return {
      showDialog: false,
      name: "",
      options: {
        nuggets: true,
        questions: true,
        language: this.$i18n.locale,
      },
      defaults: {
        time: 30,
        category_id: null,
        activate: false,
      },
      loading: false,
      rules: {
        name: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 64) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 64 }),
        ],
      },
    };
  },
  watch: {
    showDialog(val) {
      if (val) {
        this.resetName();
      }
      if (!val) this.reset();
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["add_session"]),
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    openDialog() {
      this.showDialog = true;
    },
    async addSession() {
      this.loading = true;
      let payload = {
        name: this.name,
        defaults: this.defaults,
        options: this.options,
        type: 1,
      };
      let res = await this.add_session({
        payload: payload,
      });
      this.loading = false;
      if (res && res.session_id) {
        this.closeDialog();
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
        this.$router.push({
          name: "GwSessionMain",
          params: { id: res.session_id },
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    reset() {
      this.resetName();
      this.defaults = { time: 30, category_id: null };
      this.options = { nuggets: true, questions: true };
    },
    resetName() {
      let date = new Date().toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      this.name = this.$t("gw.addSession.defaultName", { date: date });
    },
    changeLanguage(val) {
      this.options.language = val;
    },
    setCategory(v) {
      this.defaults.category_id = v;
    },
  },
};
</script>
