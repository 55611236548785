var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ViewHeader',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":_vm.$t('navigation.journeys.title'),"icon":"mdi-map-marker-path"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.addButtonType === 'journey' &&
            ['editor', 'admin', 'superadmin'].includes(_vm.role)
          ),expression:"\n            addButtonType === 'journey' &&\n            ['editor', 'admin', 'superadmin'].includes(role)\n          "}],staticStyle:{"transition":"0.4s"},attrs:{"color":"success","fab":"","dark":"","small":"","absolute":"","bottom":"","right":"","to":{ name: 'JourneyAdd' }}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.addButtonType === 'journey' &&
                  ['editor', 'admin', 'superadmin'].includes(_vm.role)
                ),expression:"\n                  addButtonType === 'journey' &&\n                  ['editor', 'admin', 'superadmin'].includes(role)\n                "}],staticStyle:{"transition":"0.4s","margin-right":"48px"},attrs:{"color":"accent","fab":"","dark":"","small":"","absolute":"","bottom":"","right":"","to":{ name: 'GwJourneys' }}},[_c('v-icon',[_vm._v("mdi-feather")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("gw.journey.generate.title")))])]),_c('AddCertificateDialog',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.addButtonType === 'certificate' &&
            ['editor', 'admin', 'superadmin'].includes(_vm.role)
          ),expression:"\n            addButtonType === 'certificate' &&\n            ['editor', 'admin', 'superadmin'].includes(role)\n          "}],scopedSlots:_vm._u([{key:"action",fn:function({ openDialog }){return [_c('v-btn',{attrs:{"fab":"","small":"","color":"success","dark":"","absolute":"","bottom":"","right":""},on:{"click":openDialog}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}])})],1)]},proxy:true},{key:"extension",fn:function(){return [_c('ViewNavigation',{staticClass:"flex-grow-1",attrs:{"nav-items":_vm.navItems,"only-tabs":""}})]},proxy:true}])}),_c('section',{staticClass:"mt-4"},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }