<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.categories.plural')"
      icon="mdi-shape"
      route-name="CategoryAdd"
    />
    <ViewLoader v-if="!categories || !groups || !substructures" />

    <section v-if="categories && substructures && groups">
      <SectionHeader :title="`${$t('general.overview')}`" />

      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn icon @click="showFilter = !showFilter">
                  <v-icon>{{
                    showFilter ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="showFilter">
                <v-row>
                  <v-col v-if="substructures" cols="6">
                    <v-autocomplete
                      v-model="filter.structures"
                      :items="structures"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.structures.plural')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="substructures" cols="6">
                    <v-autocomplete
                      v-model="filter.substructures"
                      :items="substructures"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.substructures.plural')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ substructureById(item.id).structure.name }} /
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.is_active"
                      :items="options.is_active"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.categories.isactive')"
                      :placeholder="$t('general.all')"
                      prepend-icon="mdi-comment-question"
                      outlined
                      multiple
                      hide-details
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.is_active_nugget"
                      :items="options.is_active_nugget"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.categories.isactive_nugget')"
                      :placeholder="$t('general.all')"
                      prepend-icon="mdi-school"
                      outlined
                      multiple
                      hide-details
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col v-if="groups" cols="6">
                    <v-autocomplete
                      v-model="filter.groups"
                      :items="orderedGroups"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('ng.category_groups')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      prepend-icon="mdi-magnify"
                      :label="$t('general.search')"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      @click:append="filter.search = ''"
                      @keydown="filter.page = 1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="headers"
                :items="filteredCategories"
                :items-per-page="10"
                :page.sync="filter.page"
                :search="filter.search"
                :loading="!categories"
              >
                <template #item="{ item }">
                  <tr
                    @click="
                      $router.push({
                        name: 'CategoryDetail',
                        params: { id: item.id },
                      })
                    "
                  >
                    <td>
                      <v-avatar size="32" tile>
                        <v-img :src="item.img_url" />
                      </v-avatar>
                    </td>

                    <td>
                      <span>{{ item.name }}</span>
                    </td>

                    <td>
                      <v-tooltip
                        :disabled="item.groups.length === 0"
                        right
                        color="primary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            label
                            v-bind="attrs"
                            small
                            color="grey lighten-3"
                            v-on="on"
                          >
                            {{ item.groups.length }}
                          </v-chip>
                        </template>
                        <div>
                          <div v-for="group in item.groups" :key="group.id">
                            {{ group.name }}
                          </div>
                        </div>
                      </v-tooltip>
                    </td>

                    <td>
                      <v-icon
                        small
                        :color="item.is_active ? 'success' : 'error'"
                      >
                        {{ item.is_active ? "mdi-check" : "mdi-close" }}
                      </v-icon>
                    </td>

                    <td>
                      <v-icon
                        small
                        :color="item.is_active_nugget ? 'success' : 'error'"
                      >
                        {{ item.is_active_nugget ? "mdi-check" : "mdi-close" }}
                      </v-icon>
                    </td>

                    <td>
                      <div>
                        <div v-for="(id, i) in item.substructure_ids" :key="i">
                          <v-chip
                            v-if="i < 2"
                            x-small
                            color="grey lighten-3"
                            class="mx-1 my-1"
                          >
                            {{ substructureById(id).structure.name }} /
                            {{ substructureById(id).name }}
                          </v-chip>
                        </div>
                        <v-tooltip
                          v-if="item.substructure_ids.length > 2"
                          left
                          color="primary"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              x-small
                              class="mx-1 my-1"
                              v-bind="attrs"
                              color="grey lighten-2"
                              v-on="on"
                            >
                              + {{ item.substructure_ids.length - 2 }}
                              {{ $t("resources.invitations.moreIndicator") }}
                            </v-chip>
                          </template>
                          <div>
                            <div
                              v-for="(id, i) in item.substructure_ids"
                              :key="i"
                            >
                              {{ substructureById(id).structure.name }} /
                              {{ substructureById(id).name }}
                            </div>
                          </div>
                        </v-tooltip>
                      </div>
                    </td>

                    <td>
                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div
                            class="d-flex flex-column align-start"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-chip x-small label color="primary">
                              {{ $t("resources.categories.active_questions") }}:
                              {{ item.stats.questions.count }}
                            </v-chip>
                            <v-chip x-small label color="primary" class="mt-1">
                              {{ $t("resources.categories.active_nuggets") }}:
                              {{ item.stats.nuggets.count }}
                            </v-chip>
                          </div>
                        </template>
                        <span>{{
                          $t("resources.categories.active_hint")
                        }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "CategoryOverview",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("resources.categories.img_url"),
          value: "img_url",
          sortable: false,
        },
        {
          text: this.$t("resources.categories.name"),
          value: "name",
          sortable: true,
          width: "30%",
        },
        {
          text: this.$t("ng.category_groups"),
          value: "groups",
          sortable: true,
          key: "group.length",
        },
        {
          text: this.$t("resources.categories.isactive"),
          value: "is_active",
          sortable: true,
        },
        {
          text: this.$t("resources.categories.isactive_nugget"),
          value: "is_active_nugget",
          sortable: true,
        },
        {
          text: this.$t("resources.categories.substructure_ids"),
          value: "substructure_ids",
          sortable: false,
        },
        {
          text: this.$t("resources.categories.stats"),
          value: "stats",
          sortable: false,
        },
      ],
      filter: {
        substructures: [],
        is_active: [],
        is_active_nugget: [],
        groups: [],
        search: "",
        page: 1,
        structures: [],
      },
      showFilter: false,
      options: {
        is_active: [
          { id: false, name: this.$t("resources.categories.not_isactive") },
          { id: true, name: this.$t("resources.categories.isactive") },
        ],
        is_active_nugget: [
          {
            id: false,
            name: this.$t("resources.categories.not_isactive_nugget"),
          },
          { id: true, name: this.$t("resources.categories.isactive_nugget") },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("categories", {
      categories: "get_visible_categories",
      groups: "get_categorygroups",
    }),
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    activeFilters() {
      let search = this.filter.search.length > 0 ? 1 : 0;
      return (
        this.filter.is_active.length +
        this.filter.is_active_nugget.length +
        this.filter.substructures.length +
        this.filter.structures.length +
        this.filter.groups.length +
        search
      );
    },
    filteredCategories() {
      if (!this.categories) return [];
      let filtered = [...this.categories];
      if (this.filter.is_active.length > 0) {
        filtered = filtered.filter((category) => {
          return this.filter.is_active.includes(category.is_active);
        });
      }
      if (this.filter.is_active_nugget.length > 0) {
        filtered = filtered.filter((category) => {
          return this.filter.is_active_nugget.includes(
            category.is_active_nugget
          );
        });
      }
      if (this.filter.substructures.length > 0) {
        filtered = filtered.filter((category) => {
          let included = false;
          category.substructure_ids.forEach((id) => {
            if (this.filter.substructures.includes(id)) included = true;
          });
          return included;
        });
      }
      if (this.filter.structures.length > 0) {
        filtered = filtered.filter((category) => {
          let included = false;
          category.substructures.forEach((s) => {
            if (this.filter.structures.includes(s.id)) included = true;
          });
          return included;
        });
      }
      if (this.filter.groups.length > 0) {
        filtered = filtered.filter((category) => {
          let included = false;
          category.groups.forEach((g) => {
            if (this.filter.groups.includes(g.id)) included = true;
          });
          return included;
        });
      }
      return filtered;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.content.title"),
          disabled: false,
          to: { name: "Content" },
        },
        {
          text: this.$t("resources.categories.plural"),
          disabled: true,
        },
      ];
    },
    orderedGroups() {
      return [...this.groups].sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.storeFilter();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
    this.getFilter();
  },
  destroyed() {
    this.set_categories(null);
    this.set_structures(null);
  },
  methods: {
    ...mapActions("categories", ["fetch_categories", "fetch_categorygroups"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("categories", ["set_categories"]),
    ...mapMutations("structures", ["set_structures"]),
    async fetchData() {
      await this.fetch_structures();
      await this.fetch_categories();
      await this.fetch_categorygroups();
    },
    substructureById(id) {
      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    resetFilter() {
      this.filter = {
        substructures: [],
        is_active: [],
        is_active_nugget: [],
        search: "",
        page: 1,
        structures: [],
        groups: [],
      };
    },
    storeFilter() {
      localStorage.setItem("fil_cat", JSON.stringify(this.filter));
    },
    getFilter() {
      if (localStorage.getItem("fil_cat")) {
        let filter = JSON.parse(localStorage.getItem("fil_cat"));

        this.filter.substructures = filter.substructures
          ? filter.substructures
          : [];
        this.filter.is_active = filter.is_active ? filter.is_active : [];
        this.filter.is_active_nugget = filter.is_active_nugget
          ? filter.is_active_nugget
          : [];
        this.filter.search = filter.search ? filter.search : "";
        this.filter.page = filter.page ? filter.page : 1;
        this.filter.structures = filter.structures ? filter.structures : [];
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
