<template>
  <div>
    <v-autocomplete
      v-model="selected"
      :items="sortedCategories"
      item-text="name"
      item-value="id"
      outlined
      dense
      clearable
      small-chips
      deletable-chips
      :loading="loading"
      :multiple="multiple"
      :disabled="disabled || loading"
      :return-object="returnObject"
      :label="
        label
          ? label
          : multiple
          ? $t('resources.categories.plural')
          : $t('resources.categories.singular')
      "
      background-color="white"
    >
      <template #append-outer>
        <v-tooltip left color="secondary">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              small
              color="secondary"
              v-bind="attrs"
              v-on="on"
              @click.stop="showAddCategorySheet = !showAddCategorySheet"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("ng.category_add_subtitle") }}</span>
        </v-tooltip>
      </template>
      <template #item="{ item }">
        <v-list-item-action class="mr-4">
          <v-icon>{{
            itemIsSelected(item)
              ? multiple
                ? "mdi-checkbox-marked-outline"
                : "mdi-checkbox-marked-circle-outline"
              : multiple
              ? "mdi-checkbox-blank-outline"
              : "mdi-checkbox-blank-circle-outline"
          }}</v-icon>
        </v-list-item-action>
        <v-list-item-avatar>
          <v-img :src="`${item.img_url}`" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-chip
              v-if="item.groups.length === 0"
              x-small
              label
              color="grey lighten-3"
            >
              {{ $t("ng.no_category_group_2") }}
            </v-chip>
            <v-chip
              v-for="group in item.groups"
              :key="group.id"
              x-small
              label
              color="grey lighten-3"
              class="mr-2"
            >
              {{ group.name }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip left color="secondary">
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                color="secondary"
                target="_blank"
                :to="{ name: 'CategoryDetail', params: { id: item.id } }"
                v-on="on"
                v-bind="attrs"
                @click.stop
              >
                <v-icon small>mdi-pencil-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("general.edit") }}</span>
          </v-tooltip>
        </v-list-item-action>
      </template>
    </v-autocomplete>
    <!-- :rules="mandatory ? formRule : null" -->

    <v-bottom-sheet v-model="showAddCategorySheet" inset max-width="800">
      <v-card color="grey lighten-3">
        <v-card-title>
          <span>{{ $t("ng.category_add_subtitle") }}</span>
          <v-spacer />
          <v-btn icon small @click="showAddCategorySheet = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <!-- <v-card-subtitle>Subtitle</v-card-subtitle> -->
        <v-card-text>
          <v-form v-model="valid" :disabled="loading">
            <v-container>
              <v-row>
                <v-col cols="12" sm="9" class="pt-8">
                  <InputTextfield
                    v-model="newCategory.name"
                    listen-to-input
                    :rules="rules.name"
                    :label="$t('resources.categories.name')"
                    translatable
                  />
                </v-col>
                <v-col cols="12" sm="3">
                  <CategoryImageChanger
                    :image-input="newCategory.image_url"
                    @image-changed="changeImage"
                  />
                </v-col>
                <v-col cols="12">
                  <InputTextarea
                    v-model="newCategory.description"
                    listen-to-input
                    :rules="rules.description"
                    :label="$t('resources.nuggets.description')"
                    height="120"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="newCategory.substructure_ids"
                    :items="sortedSubstructures"
                    item-text="name"
                    item-value="id"
                    attach
                    chips
                    dense
                    small-chips
                    deletable-chips
                    :messages="
                      !['superadmin', 'admin'].includes(role)
                        ? $t('general.errors.admin_only')
                        : ''
                    "
                    :disabled="!['superadmin', 'admin'].includes(role)"
                    multiple
                    :hint="$t('ng.category_substructure_hint')"
                    persistent-hint
                    outlined
                    background-color="white"
                    :label="$t('resources.categories.substructure_ids')"
                    :menu-props="{ top: true, offsetY: true }"
                  >
                    <template #item="{ item }">
                      {{ item.structure.name }} / {{ item.name }}
                    </template>
                    <template #selection="{ item }">
                      <v-chip small color="primary" label>
                        {{ substructureById(item.id).structure.name }} /
                        {{ item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6">
                  <CategorygroupSelector @changed-groups="setGroups" dense />
                </v-col>
              </v-row>
              <div class="d-flex">
                <v-spacer />
                <v-btn
                  color="error"
                  text
                  small
                  :disabled="loading"
                  @click="resetForm"
                >
                  {{ $t("general.clear") }}
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  small
                  class="ml-2"
                  :loading="loading"
                  :disabled="!valid"
                  @click="saveForm"
                >
                  {{ $t("general.save") }}
                </v-btn>
              </div>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CategoryImageChanger from "@/components/categories/CategoryImageChanger";
import CategorygroupSelector from "@/components/categories/CategorygroupSelector";

export default {
  name: "CategorySelector",
  components: {
    CategoryImageChanger,
    CategorygroupSelector,
  },
  props: {
    inputValue: {
      type: [String, Number, Array],
      required: false,
    },
    returnObject: {
      // TODO: check icon if true
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      selected: null,
      showAddCategorySheet: false,
      valid: false,
      newCategory: {
        name: "",
        description: "",
        image_url: "1.png",
        substructure_ids: [],
        group_ids: [],
      },
      rules: {
        name: [
          (v) =>
            (!!v && v.length >= 3) ||
            this.$t("general.errors.not_enough_chars"),
          (v) =>
            (!!v && v.length <= 32) ||
            this.$t("resources.categories.errors.name_too_long"),
        ],
        description: [
          (v) =>
            (!!v && v.length >= 3) ||
            this.$t("general.errors.not_enough_chars"),
          (v) =>
            (!!v && v.length <= 128) ||
            this.$t("resources.categories.errors.name_too_long"), // TODO max length?
        ],
      },
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    ...mapGetters("structures", { substructures: "get_substructures" }),
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return (categories = categories.sort((a, b) =>
        a.name.localeCompare(b.name)
      ));
    },
    sortedSubstructures() {
      if (!this.substructures) return [];
      var substructures = [...this.substructures];
      return substructures.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  mounted() {
    if (this.multiple) this.selected = [];
    if (this.inputValue) this.selected = this.inputValue;
  },
  destroyed() {
    this.set_categories(null);
    this.set_substructures(null);
  },
  watch: {
    selected(v) {
      this.$emit("change", v);
    },
    async showAddCategorySheet(v) {
      if (v) this.fetchStructures();
    },
  },
  methods: {
    ...mapActions("categories", ["fetch_categories", "add_category"]),
    ...mapMutations("categories", ["set_categories"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("structures", ["set_substructures"]),
    async fetchStructures() {
      await this.fetch_structures();
    },
    substructureById(id) {
      if (!this.substructures) return [];

      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    async fetchData() {
      this.loading = true;
      await this.fetch_categories();
      this.loading = false;
    },
    resetForm() {
      this.newCategory = {
        name: "",
        description: "",
        image_url: "1.png",
        substructure_ids: [],
        group_ids: [],
      };
    },
    changeImage(image) {
      this.newCategory.image_url = image.name;
    },
    setGroups(val) {
      this.newCategory.group_ids = val;
    },
    async saveForm() {
      this.loading = true;
      var payload = this.newCategory;
      payload.language = this.$i18n.locale;
      let res = await this.add_category({
        payload: payload,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text:
            this.$t("resources.categories.singular") +
            " " +
            this.$t("general.createSuccess"),
        });
        this.resetForm();
        this.showAddCategorySheet = false;
        await this.fetchData();
        if (this.multiple) {
          this.selected.push(res.category_id);
        } else {
          this.selected = res.category_id;
        }
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    itemIsSelected(item) {
      if (this.multiple) return this.selected.includes(item.id);
      return this.selected === item.id;
    },
  },
};
</script>
