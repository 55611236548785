<template>
  <div>
    <section v-if="source">
      <v-container v-if="source.mimetype !== 'file'" class="pt-0">
        <v-textarea
          :value="source.body"
          outlined
          background-color="white"
          flat
          :label="$t('gw.journey.source')"
          hide-details
          height="800"
          no-resize
          readonly
          :loading="loading"
          style="border: 1px solid #ccc"
        />
      </v-container>

      <v-container v-if="source.mimetype === 'file'" class="pt-0">
        <p>
          {{ $t("gw.journey.presentation.slide_hint") }}
        </p>
        <v-row>
          <v-col
            v-for="(page, idx) in source.pages"
            :key="`page-${idx}`"
            cols="12"
            md="6"
          >
            <SlidePreviewDialog
              :slide="page"
              :slide-count="source.pages.length"
              :selected="pageIsActive(page)"
            >
              <template #action="{ openDialog }">
                <v-card
                  outlined
                  style="overflow: hidden"
                  height="200"
                  @click="openDialog()"
                >
                  <v-img :src="page.img" cover max-height="200">
                    <div class="d-flex justify-space-between pa-2">
                      <v-chip color="secondary" small class="pr-2 elevation-4">
                        <span>{{ page.number }}</span>
                        <v-avatar
                          right
                          :color="pageIsActive(page) ? 'success' : 'error'"
                          size="12"
                          style="margin-right: -8px"
                        >
                          <v-icon x-small>{{
                            pageIsActive(page) ? "mdi-check" : "mdi-close"
                          }}</v-icon>
                        </v-avatar>
                      </v-chip>
                      <v-btn
                        fab
                        color="info"
                        x-small
                        @click.stop="openDialog()"
                      >
                        <v-icon> mdi-magnify </v-icon>
                      </v-btn>
                    </div>
                  </v-img>
                </v-card>
              </template>
            </SlidePreviewDialog>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SlidePreviewDialog from "@/components/files/SlidePreviewDialog";

export default {
  name: "GwJourneySource",
  components: { SlidePreviewDialog },
  data() {
    return {
      loading: true,
      activePages: [],
    };
  },
  computed: {
    ...mapGetters("ghostwriter", {
      journey: "get_gw_journey",
      source: "get_source",
    }),
  },
  mounted() {
    this.fetchSource();
  },
  destroyed() {
    this.set_source(null);
  },
  methods: {
    ...mapActions("ghostwriter", ["fetch_source"]),
    ...mapMutations("ghostwriter", ["set_source"]),
    async fetchSource() {
      this.loading = true;
      await this.fetch_source({ id: this.journey.source_id });
      this.loading = false;
    },
    pageIsActive(page) {
      var selected = this.source.body.split(",").map((p) => parseInt(p));
      return selected.includes(page.number);
    },
  },
};
</script>
