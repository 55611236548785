<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined>
            <v-form v-model="valid">
              <v-card-title>
                <span class="secondary--text">{{
                  $t("gw.session_options")
                }}</span>
                <v-spacer />
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <h5 class="white--text">
                      {{ $t("resources.journeys.propNames.name") }}
                    </h5>
                    <v-text-field
                      v-model="name"
                      :label="$t('resources.categories.name')"
                      outlined
                      dense
                      background-color="white"
                      :rules="rules.notEmpty"
                      :disabled="disabled"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <h5 class="white--text">
                      {{ $t("gw.default_category") }}
                    </h5>
                    <CategorySelector
                      :input-value="defaults.category_id"
                      :label="$t('gw.default_category')"
                      @change="setCategory"
                    />
                    <!-- <v-autocomplete
                      v-model="defaults.category_id"
                      :items="sortedCategories"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      small-chips
                      clearable
                      background-color="white"
                      :disabled="loading || !categories || disabled"
                      :label="$t('gw.default_category')"
                    /> -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-card flat>
                      <v-card-text class="py-3">
                        <v-slider
                          v-model="defaults.time"
                          :label="$t('ng.question_time')"
                          thumb-label="always"
                          min="0"
                          max="180"
                          color="secondary"
                          class="pt-1"
                          dense
                          track-color="secondary lighten-3"
                          hide-details
                          :disabled="disabled"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="align-end">
                <v-btn
                  color="error"
                  depressed
                  small
                  :disabled="disabled"
                  @click="deleteSession()"
                >
                  <v-icon x-small class="mr-2"> mdi-delete </v-icon>
                  <span>{{ $t("gw.deleteSession") }}</span>
                </v-btn>
                <v-spacer />
                <v-btn
                  color="success"
                  text
                  small
                  :loading="loading"
                  :disabled="
                    ![0, 7].includes(session.status) ||
                    !changed ||
                    disabled ||
                    !valid
                  "
                  @click="patchSession()"
                >
                  <v-icon small class="mr-2"> mdi-content-save </v-icon>
                  <span>{{ $t("general.save") }}</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CategorySelector from "@/components/global/CategorySelector";

export default {
  name: "GwSessionBulkSettings",
  components: {
    CategorySelector,
  },
  data() {
    return {
      defaults: null,
      name: "",
      rules: {
        notEmpty: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          //(v) => !!v && v.length <= 64 || this.$t('ng.too_many_chars_hint', { count: v.length, max: 64 })
        ],
      },
      valid: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_bulk_session" }),
    ...mapGetters("auth", { licences: "get_licences" }),
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
    changed() {
      let changed = false;
      if (!this.session.defaults) return false;
      if (this.name !== this.session.name) changed = true;
      if (this.defaults.time !== this.session.defaults.time) changed = true;
      if (this.defaults.category_id !== this.session.defaults.category_id)
        changed = true;
      if (this.defaults.activate !== this.session.defaults.activate)
        changed = true;
      return changed;
    },
    disabled() {
      return (
        !this.licences.ghostwriter ||
        this.licenceExpired ||
        (this.session && ![0, 7].includes(this.session.status))
      );
    },
  },
  beforeMount() {
    this.defaults = JSON.parse(JSON.stringify(this.session.defaults));
    this.name = this.session.name;
  },
  methods: {
    ...mapActions("ghostwriter", ["patch_session", "delete_session"]),
    async patchSession() {
      this.loading = true;
      let props = [
        { name: "name", value: this.name },
        { name: "defaults", value: this.defaults },
      ];
      let payload = {
        session_id: this.session.id,
        session_type: this.session.type,
        properties: props,
      };
      let res = await this.patch_session({
        payload: payload,
      });
      this.loading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    async deleteSession() {
      var confirm = window.confirm(this.$t("gw.confirm_delete_session"));
      if (!confirm) return false;
      this.loading = true;
      let res = await this.delete_session({
        id: this.session.id,
      });
      this.loading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        this.$router.push({ name: "GwSessionBulk" });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    setCategory(v) {
      this.defaults.category_id = v;
    },
  },
};
</script>
