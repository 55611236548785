import api from "@/services/api";
import fileserverapi from "@/services/fileserverapi";

export const fetch_upload_token = async (_, config = {}) => {
  try {
    let res = await api.get("admin/files/token/upload", config);
    /* if (res._status === 200) {
      commit("set_upload_token", res.token);
    } */
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const fetch_download_token = async (_, payload, config = {}) => {
  try {
    let res = await api.get(
      `admin/files/token/download/${payload.id}/${payload.variant}`,
      config
    );
    /* if (res._status === 200) {
      commit("set_download_token", res.download_token);
    } */
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const upload_file = async (
  { dispatch },
  { payload, refetch = false, config = {} }
) => {
  try {
    let res = await fileserverapi.post("web/upload/", payload, config);
    if (refetch) {
      dispatch("fetch_files");
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const download_file = async (_, { token, page, config = {} }) => {
  try {
    let res = await fileserverapi.get(
      `web/download/${token}?p=${page}`,
      config
    );
    /* if (res._status === 200) {
      commit("set_download_token", res.download_token);
    } */
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const fetch_files = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/files/", config);
    if (res._status === 200) {
      commit("set_files", res.files);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const fetch_file = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.get(`admin/files/${id}`, config);
    if (res._status === 200) {
      commit("set_file", res.file);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const create_source_from_file = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post(`admin/cnt/sources/file`, payload, config);
    /* if (res._status === 200) {
      commit("set_source", res.source);
    } */
    return res;
  } catch (err) {
    console.error(err);
  }
};
