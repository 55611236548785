<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      max-width="640"
      scrollable
      :loading="loading"
    >
      <v-card color="grey lighten-3" :loading="loading">
        <v-card-title>{{ $t("gw.presentation.upload") }}</v-card-title>
        <v-card-subtitle>{{ getSubtitle }}</v-card-subtitle>
        <v-card-text v-if="page === 1" class="pt-2">
          <v-row dense>
            <v-col cols="12">
              <v-form v-model="valid">
                <v-text-field
                  v-model="title"
                  outlined
                  dense
                  :rules="rules.title"
                  :label="$t('resources.nuggets.title')"
                  :disabled="loading || uploading"
                  background-color="white"
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="newFile"
                outlined
                dense
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                accept=".ppt,.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                background-color="white"
                :label="$t('ng.select_new_file')"
              />
            </v-col>
            <v-col cols="12">
              <LanguageChooserInline
                colored
                dense
                class="pb-0"
                hidedetails
                disabled
                :label="$t('general.language')"
                :initial="'de-DE'"
              />
              <v-alert type="info" color="info" text class="caption mt-4" dense>
                {{ $t("gw.presentation.only_german") }}
              </v-alert>
            </v-col>
          </v-row>
          <v-alert v-if="upload_error" dismissible type="error">{{
            $t("ng.upload_error")
          }}</v-alert>
        </v-card-text>

        <v-card-text v-if="page === 2">
          <v-alert v-if="uploading" type="info" text>
            <template #prepend>
              <v-progress-circular width="3" indeterminate />
            </template>
            <span class="pl-4">{{ $t("ng.upload_feedback") }}</span>
          </v-alert>
        </v-card-text>

        <v-card-text v-if="page === 3 && !source_is_patched">
          <v-row dense>
            <v-col cols="12" class="mt-2">
              <v-form v-model="valid">
                <v-text-field
                  v-model="title"
                  outlined
                  dense
                  :rules="rules.title"
                  :label="$t('resources.nuggets.title')"
                  :disabled="loading || uploading"
                  background-color="white"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
          <v-item-group
            v-if="source && source.pages"
            v-model="activePages"
            multiple
          >
            <v-row>
              <v-col
                v-for="(page, idx) in source.pages"
                :key="`page-${idx}`"
                cols="12"
                md="6"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    outlined
                    style="overflow: hidden"
                    height="120"
                    @click="toggle"
                  >
                    <v-img :src="page.img" contain max-height="120">
                      <div class="d-flex justify-space-between pa-2">
                        <v-chip
                          color="secondary"
                          small
                          class="pr-2 elevation-4"
                        >
                          <span>{{ page.number }}</span>
                          <v-avatar
                            right
                            :color="active ? 'success' : 'error'"
                            size="12"
                            style="margin-right: -8px"
                          >
                            <v-icon x-small>{{
                              active ? "mdi-check" : "mdi-close"
                            }}</v-icon>
                          </v-avatar>
                        </v-chip>
                        <v-spacer />
                        <SlidePreviewDialog
                          :slide="page"
                          :slide-count="source.pages.length"
                          :selected="active"
                        >
                          <template #action="{ openDialog }">
                            <v-btn
                              fab
                              color="info"
                              x-small
                              @click.stop="openDialog()"
                            >
                              <v-icon> mdi-magnify </v-icon>
                            </v-btn>
                          </template>
                        </SlidePreviewDialog>
                      </div>
                    </v-img>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-card-text>

        <v-card-text v-if="page === 4">
          <v-alert type="info" text>
            <template #prepend>
              <v-progress-circular width="3" indeterminate />
            </template>
            <span class="pl-4">{{ $t("ng.upload_feedback") }}</span>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-btn
            small
            text
            :disabled="loading || uploading"
            @click="closeDialog()"
            >{{ $t("general.close") }}</v-btn
          >
          <v-spacer />
          <v-btn
            small
            text
            :disabled="page <= 1 || loading || uploading"
            @click="handlePageDown()"
            >{{ $t("general.back") }}</v-btn
          >
          <v-btn
            small
            text
            :disabled="pageUpDisabled"
            @click="handlePageUp()"
            >{{ page < 3 ? $t("general.next") : $t("general.apply") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SlidePreviewDialog from "@/components/files/SlidePreviewDialog";
import LanguageChooserInline from "@/components/global/LanguageChooserInline";

export default {
  name: "PresentationUploadDialog",
  components: { SlidePreviewDialog, LanguageChooserInline },
  props: {
    mimetype: {
      type: String, // filter for list
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      selectedFileSource: null,
      newFile: null,
      page: 1,
      upload_error: false,
      uploading: false,
      progress: null,
      uploadedFile: null,
      activePages: [],
      source_is_patched: false,
      rules: {
        title: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 64) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 64 }),
        ],
      },
      title: "",
      valid: false,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { source: "get_source" }),
    pageUpDisabled() {
      var disabled = false;
      if (this.page > 4 || this.loading || this.uploading) disabled = true;
      if (this.page === 1 && (!this.newFile || !this.valid)) disabled = true;
      if (this.page === 3 && this.activePages.length < 1) disabled = true;
      if (this.page === 4 && !this.source) disabled = true;
      return disabled;
    },
    getSubtitle() {
      var texts = [
        this.$t("gw.presentation.upload_texts.1"),
        this.$t("ng.uploading"), //this.$t("gw.presentation.upload_texts.2"),
        this.$t("gw.presentation.upload_texts.3"),
        this.$t("general.confirm"),
      ];
      return `${this.$t("gw.presentation.upload_texts.page")}: ${this.page}: ${
        texts[this.page - 1]
      }`;
    },
  },
  methods: {
    ...mapActions("files", [
      "fetch_upload_token",
      "upload_file",
      "create_source_from_file",
      "fetch_file",
    ]),
    ...mapActions("ghostwriter", [
      "fetch_source",
      "patch_source",
      "fetch_fileserver_job",
    ]),
    ...mapMutations("files", ["set_files"]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.newFile = null;
      this.page = 1;
      this.activePages = [];
      this.source_is_patched = false;
      this.title = "";
    },
    async uploadFile() {
      this.loading = true;
      this.upload_error = false;
      this.uploading = true;

      var res_token = await this.fetch_upload_token();

      if (res_token._status !== 200 || !res_token.token) {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
        this.page = 1;
        this.upload_error = true;
        return false;
      }

      var res = await this.upload_file({
        payload: {
          file: this.newFile,
          token: res_token.token,
          lang: "de-DE", // TODO: remove hardcoded value
        },
        refetch: false,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
      });
      if (res._status === 200) {
        this.uploadedFile = res.file;
        this.checkFileJob(res.job.job_id);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
        this.page = 1;
        this.upload_error = true;
      }

      this.loading = false;
    },
    async checkFileJob(id) {
      var res = await this.fetch_fileserver_job({
        id: id,
      });
      if (
        !res ||
        (res &&
          res.job &&
          (res.job.status === "SUCCESS" || res.job.status === "FAILURE"))
      ) {
        this.cancelJob();
        if (res.job.status === "SUCCESS") {
          this.createSource();
        } else {
          this.page = 1;
          this.upload_error = true;
        }
        return false;
      }
      if (!res.job || !id) return false;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (this.progress) return false;
      this.progress = setInterval(
        function () {
          this.checkFileJob(id);
        }.bind(this),
        5000
      );
    },
    async checkSource(id) {
      var res = await this.fetch_source({
        id: id,
      });
      if (!res || res._status === 500) {
        this.page = 1;
        this.cancelJob();
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
        return false;
      }
      if (!res || (res && res.source.pages && res.source.pages.length > 0)) {
        this.cancelJob();
        this.uploading = false;
        this.setActivePages();
        if (this.source_is_patched) this.applySource();
        return false;
      }
      if (!res.source || !id) return false;
      /* await new Promise((resolve) => setTimeout(resolve, 1000)); */
      if (this.progress) return false;
      this.progress = setInterval(
        function () {
          this.checkSource(id);
        }.bind(this),
        5000
      );
    },
    async cancelJob() {
      this.uploading = false;
      this.progress = clearInterval(this.progress);
    },
    async createSource() {
      this.loading = true;
      this.uploading = true;
      var payload = {
        title: this.title,
        lang: "de-DE",
        file_id: this.uploadedFile.id,
      };
      var res = await this.create_source_from_file({ payload: payload });
      this.uploading = false;

      if (res._status === 200) {
        this.page = 3;
        this.checkSource(res.source.id);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
      this.loading = false;
    },
    handlePageDown() {
      if (this.page === 1) return false;
      if (this.page === 3) {
        this.page = 1;
        this.activePages = [];
        return false;
      }
      this.page--;
    },
    handlePageUp() {
      if (this.page === 1) this.uploadFile();
      if (this.page === 3) {
        this.patchSource();
      }
      this.page++;
    },
    async patchSource() {
      this.loading = true;
      var pages = [...this.activePages].sort((a, b) => a - b).map((p) => p + 1);
      var res = await this.patch_source({
        payload: {
          pages: pages,
          title: this.title,
          lang: this.source.lang,
        },
        id: this.source.id,
        config: null,
      });
      this.loading = false;

      if (res._status === 200) {
        this.source_is_patched = true;
        this.checkSource(res.source.id);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }

      this.$notify({
        type: res && res._status === 200 ? "success" : "error",
        title:
          res && res._status === 200
            ? this.$t("general.success")
            : this.$t("general.error"),
      });
    },
    applySource() {
      this.closeDialog();
      this.$emit("filesource", this.source);
    },
    setActivePages() {
      if (!this.source || !this.source.pages) return false;
      var pages = [...this.source.pages];
      pages.forEach((_, i) => this.activePages.push(i));
    },
  },
};
</script>
