<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="!loading">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card outlined :loading="!journeys">
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn
                  v-if="display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  v-if="!display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="display.filters">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.status"
                      :items="options.status"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      item-text="name"
                      item-value="type"
                      :label="$t('resources.journeys.status')"
                      prepend-icon="mdi-list-status"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col v-if="substructures" cols="6">
                    <v-autocomplete
                      v-model="filter.structures"
                      :items="structures"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.structures.plural')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="substructures" cols="6">
                    <v-autocomplete
                      v-model="filter.substructures"
                      :items="substructures"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.substructures.plural')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ substructureById(item.id).structure.name }} /
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      class="mt-0 pt-0"
                      prepend-icon="mdi-magnify"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      :label="$t('general.search')"
                      @click:append="filter.search = ''"
                      @keydown="filter.page = 1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="tableHeaders"
                :items="journeysFiltered"
                :items-per-page="10"
                :page.sync="filter.page"
                :search="filter.search"
                :loading="!journeys"
              >
                <template #item="journey">
                  <tr
                    :style="
                      !journey.item.is_generating
                        ? 'cursor: pointer'
                        : 'cursor: default; background: white'
                    "
                    @click="goToJourney(journey.item)"
                  >
                    <td>
                      <v-tooltip v-if="!journey.item.is_generating" right>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            :color="getStatusColor(journey.item.status)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ getStatusIcon(journey.item.status) }}
                          </v-icon>
                        </template>
                        <span class="caption">{{
                          $t(
                            `resources.journeys.statuses.${journey.item.status}`
                          )
                        }}</span>
                      </v-tooltip>
                      <v-tooltip v-if="!!journey.item.is_generating" right>
                        <template #activator="{ on, attrs }">
                          <v-progress-circular
                            v-bind="attrs"
                            v-on="on"
                            indeterminate
                            color="primary"
                            size="24"
                            width="2"
                          />
                        </template>
                        <span class="caption">{{
                          $t("gw.journey.is_importing")
                        }}</span>
                      </v-tooltip>
                    </td>
                    <td>{{ journey.item.name }}</td>
                    <td>
                      <span v-if="journey.item.substructures.length == 0"
                        >-</span
                      >
                      <div v-else>
                        <v-chip x-small>
                          {{
                            substructureById(journey.item.substructures[0])
                              .structure.name
                          }}
                          /
                          {{
                            substructureById(journey.item.substructures[0]).name
                          }}
                        </v-chip>
                        <v-tooltip
                          v-if="journey.item.substructures.length > 1"
                          bottom
                        >
                          <template #activator="{ on, attrs }">
                            <v-chip
                              x-small
                              class="ml-2"
                              v-bind="attrs"
                              color="grey lighten-3"
                              v-on="on"
                            >
                              + {{ journey.item.substructures.length - 1 }}
                            </v-chip>
                          </template>
                          <div>
                            <div
                              v-for="(id, i) in journey.item.substructures"
                              :key="id"
                              class="caption"
                            >
                              <span v-if="i > 0">
                                {{ substructureById(id).structure.name }} /
                                {{ substructureById(id).name }}
                              </span>
                            </div>
                          </div>
                        </v-tooltip>
                      </div>
                    </td>
                    <td>
                      <small>{{ getStartDate(journey.item) }}</small>
                    </td>
                    <td>
                      <small>{{ getEndDate(journey.item) }}</small>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader.vue";

export default {
  name: "JourneyCurrent",
  components: {
    ViewLoader,
  },
  data() {
    return {
      loading: true,
      display: {
        filters: false,
      },
      filter: {
        substructures: [],
        status: [],
        search: "",
        page: 1,
        structures: [],
      },
      options: {
        status: [
          {
            type: "unpublished",
            name: this.$t("resources.journeys.statuses.unpublished"),
          },
          {
            type: "published",
            name: this.$t("resources.journeys.statuses.published"),
          },
          {
            type: "active",
            name: this.$t("resources.journeys.statuses.active"),
          },
          //{ type: 'paused', name: this.$t('resources.journeys.statuses.paused') },
          {
            type: "closed",
            name: this.$t("resources.journeys.statuses.closed"),
          },
          {
            type: "expired",
            name: this.$t("resources.journeys.statuses.expired"),
          },
        ],
      },
      tableHeaders: [
        {
          text: this.$t("resources.journeys.status"),
          value: "status",
          width: "10%",
        },
        { text: this.$t("resources.journeys.journey.singular"), value: "name" },
        {
          text: this.$t("resources.substructures.plural"),
          value: "substructures",
          width: "25%",
          sortable: false,
        },
        {
          text: this.$t("resources.journeys.propNames.start_at"),
          value: "start_at",
        },
        {
          text: this.$t("resources.journeys.propNames.end_at"),
          value: "end_at",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("journeys", { journeys: "get_journeys" }),
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    journeysFiltered() {
      if (this.journeys) {
        let filtered = this.journeys.filter(
          (j) => !["closed", "expired"].includes(j.status)
        );

        if (this.filter.status.length > 0) {
          filtered = filtered.filter((journey) => {
            return this.filter.status.includes(journey.status);
          });
        }

        if (this.filter.substructures.length > 0) {
          return filtered.filter((journey) => {
            return (
              journey.substructures.filter((substructure_id) => {
                return this.filter.substructures.includes(substructure_id);
              }).length > 0
            );
          });
        }
        if (this.filter.structures.length > 0) {
          return filtered.filter((journey) => {
            let substructures = [];
            journey.substructures.forEach((s) =>
              substructures.push(this.substructureById(s))
            );
            return (
              substructures.filter((s) => {
                if (!s) return null;
                return this.filter.structures.includes(s.structure.id);
              }).length > 0
            );
          });
        }

        return filtered;
      } else return [];
    },
    activeFilters() {
      let search = this.filter.search.length > 0 ? 1 : 0;
      return (
        this.filter.status.length +
        this.filter.substructures.length +
        search +
        this.filter.structures.length
      );
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("navigation.journeys.title"),
          disabled: true,
        },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.storeFilter();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager", "editor"]);
    this.getFilter();
  },
  destroyed() {
    this.set_journeys(null);
    this.set_structures(null);
    this.set_substructures(null);
  },
  methods: {
    ...mapActions("journeys", ["fetch_journeys"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("journeys", ["set_journeys"]),
    ...mapMutations("structures", ["set_structures", "set_substructures"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_journeys();
      await this.fetch_structures();
      this.loading = false;
    },
    resetFilter() {
      this.filter.status = [];
      this.filter.substructures = [];
      this.filter.search = "";
      this.filter.page = 1;
      this.filter.structures = [];
    },
    goToJourney(journey) {
      if (journey.is_generating) return false;
      this.$router.push({ name: "JourneySummary", params: { id: journey.id } });
    },
    getStatusIcon(status) {
      if (status == "unpublished") return "mdi-circle-edit-outline";
      if (status == "published") return "mdi-play-circle-outline";
      if (status == "active") return "mdi-play-circle-outline";
      if (status == "paused") return "mdi-pause-circle-outline";
      if (status == "closed") return "mdi-checkbox-marked-circle-outline";
      if (status == "expired") return "mdi-checkbox-marked-circle-outline";
      // deleted mdi-close-circle-outline
      return "mdi-circle";
    },
    getStatusColor(status) {
      if (status == "unpublished") return "warning";
      if (status == "published") return "primary";
      if (status == "active") return "success";
      if (status == "paused") return "warning";
      if (status == "expired" || status == "closed") return "primary";
      return "primary";
    },
    substructureById(id) {
      let substr = this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
      return substr
        ? substr
        : {
            id: -1,
            name: "INVALID SUBTRUCTURE",
            structure: { id: -1, name: "ERROR" },
          };
    },
    getStartDate(journey) {
      if (journey.start_at) {
        return new Date(journey.start_at).toLocaleDateString();
      }
      return "";
    },
    getEndDate(journey) {
      if (journey.end_at) {
        return new Date(journey.end_at).toLocaleDateString();
      }
      return "";
    },
    storeFilter() {
      localStorage.setItem("fil_jrn", JSON.stringify(this.filter));
    },

    // !!!!!!!!
    getFilter() {
      if (localStorage.getItem("fil_jrn")) {
        let filter = JSON.parse(localStorage.getItem("fil_jrn"));

        this.filter.substructures = filter.substructures
          ? filter.substructures
          : [];
        this.filter.status = filter.status ? filter.status : [];
        this.filter.search = filter.search ? filter.search : "";
        this.filter.page = filter.page ? filter.page : 1;
        this.filter.structures = filter.structures ? filter.structures : [];
      }
    },
  },
};
</script>
