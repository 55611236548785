<template>
  <div>
    <v-card
      color="grey lighten-3"
      outlined
      :dark="journeyPublished"
      style="border-color: rgba(0, 0, 0, 0.12) !important"
    >
      <v-card-text>
        <v-stepper
          v-model="stepper"
          non-linear
          value="3"
          alt-labels
          flat
          class="transparent"
        >
          <v-stepper-header>
            <v-tooltip
              bottom
              max-width="640"
              color="info"
              :disabled="contentAvailable"
            >
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-stepper-step
                    :complete="contentAvailable"
                    :editable="!contentAvailable"
                    :rules="[() => contentAvailable]"
                    step="1"
                    color="success darken-1"
                    complete-icon="mdi-check"
                    error-icon="mdi-alert-circle"
                    edit-icon="mdi-check"
                    @click="
                      !contentAvailable
                        ? $router.push({ name: 'JourneyBundles' })
                        : false
                    "
                  >
                    <span class="caption">{{
                      contentAvailable
                        ? $t("ng.content_added")
                        : $t("gw.journey.steps.add_content.title")
                    }}</span>
                  </v-stepper-step>
                </div>
              </template>
              <span>{{ $t("gw.journey.steps.add_content.subtitle") }}</span>
            </v-tooltip>

            <v-divider></v-divider>

            <v-tooltip
              bottom
              max-width="640"
              color="info"
              :disabled="contentValid"
            >
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-stepper-step
                    :complete="contentValid"
                    :editable="!contentValid"
                    :rules="[() => contentValid]"
                    step="1"
                    color="success darken-1"
                    complete-icon="mdi-check"
                    error-icon="mdi-alert-circle"
                    edit-icon="mdi-check"
                    @click="
                      !contentValid
                        ? $router.push({ name: 'JourneyBundles' })
                        : false
                    "
                  >
                    <span class="caption">{{
                      contentValid
                        ? $t("gw.journey.steps.valid_content.title_1")
                        : $t("gw.journey.steps.valid_content.title_2")
                    }}</span>
                  </v-stepper-step>
                </div>
              </template>
              <span>{{ $t("gw.journey.steps.valid_content.subtitle") }}</span>
            </v-tooltip>

            <v-divider></v-divider>

            <v-tooltip
              bottom
              max-width="640"
              color="info"
              :disabled="structuresSet"
            >
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-stepper-step
                    :complete="structuresSet"
                    :editable="!structuresSet"
                    step="3"
                    color="success darken-1"
                    complete-icon="mdi-check"
                    error-icon="mdi-close"
                    edit-icon="mdi-check"
                    @click="
                      !structuresSet
                        ? $router.push({ name: 'JourneyStructures' })
                        : false
                    "
                  >
                    <span class="caption">{{
                      structuresSet
                        ? $t("ng.structures_set")
                        : $t("gw.journey.steps.visibility.title")
                    }}</span>
                  </v-stepper-step>
                </div>
              </template>
              <span>{{ $t("gw.journey.steps.visibility.subtitle") }}</span>
            </v-tooltip>

            <v-divider></v-divider>
            <v-tooltip
              bottom
              max-width="640"
              color="info"
              :disabled="
                ['active'].includes(journey.status) ||
                (['published'].includes(journey.status) && !!journey.start_at)
              "
            >
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-stepper-step
                    :complete="
                      ['active'].includes(journey.status) ||
                      (['published'].includes(journey.status) &&
                        !!journey.start_at)
                    "
                    :editable="
                      ['unpublished'].includes(journey.status) ||
                      (['published'].includes(journey.status) &&
                        !journey.start_at)
                    "
                    step="4"
                    color="success darken-1"
                    complete-icon="mdi-check"
                    error-icon="mdi-alert-circle"
                    edit-icon="mdi-check"
                    @click="
                      !['active', 'published'].includes(journey.status) &&
                      contentAvailable &&
                      contentValid &&
                      structuresSet
                        ? changeStatus('published')
                        : ['published'].includes(journey.status) &&
                          !journey.start_at
                        ? changeStatus('active')
                        : false
                    "
                  >
                    <span class="caption">{{
                      ["published"].includes(journey.status) &&
                      !journey.start_at
                        ? $t("certificate.activation.activate")
                        : ["published"].includes(journey.status) &&
                          !!journey.start_at
                        ? $t("ng.starts_at_date", {
                            date: new Date(
                              journey.start_at
                            ).toLocaleDateString(),
                          })
                        : $t("gw.journey.steps.publish.title")
                    }}</span>
                  </v-stepper-step>
                </div>
              </template>
              <span>{{ $t("gw.journey.steps.publish.subtitle") }}</span>
            </v-tooltip>
          </v-stepper-header>
        </v-stepper>
      </v-card-text>
    </v-card>
    <JourneyDialogStatus
      v-if="showStatusDialog"
      :dialog="showStatusDialog"
      :journey="journey"
      :new-status="newStatus"
      @close="showStatusDialog = false"
    />
  </div>
</template>

<script>
import JourneyDialogStatus from "@/components/journeys/dialogs/JourneyDialogStatus";
import { mapGetters } from "vuex";

export default {
  name: "JourneyStepper",
  components: { JourneyDialogStatus },
  props: {
    /* journey: {
      type: Journey,
      required: true,
    }, */
  },
  data() {
    return {
      stepper: 1,
      highlightStatusChanger: false,
      showStatusDialog: false,
      newStatus: "",
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
    }),
    contentAvailable() {
      if (
        !this.journey ||
        !this.journey.bundles ||
        this.journey.bundles.length === 0
      )
        return false;
      return true;
    },
    contentValid() {
      if (
        !this.journey ||
        !this.journey.bundles ||
        this.journey.bundles.length === 0
      )
        return false;

      var valid = true;
      this.journey.bundles.forEach((b) => {
        if (!b.steps || b.steps.length === 0) valid = false;
      });
      return valid;
    },
    structuresSet() {
      return (
        !!this.journey.substructures && this.journey.substructures.length > 0
      );
    },
    journeyPublished() {
      return false;
    },
  },
  watch: {
    highlightStatusChanger: {
      handler: function (v) {
        this.$emit("highlight", v);
      },
    },
  },
  methods: {
    changeStatus(newStatus) {
      this.newStatus = newStatus;
      this.showStatusDialog = true;
    },
  },
};
</script>

<style>
@media only screen and (max-width: 959.98px) {
  .v-stepper__header {
    flex-direction: column !important;
  }
  .v-stepper__step {
    padding: 0;
  }
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block;
  }
}
</style>
