<template>
  <div>
    <v-select
      v-model="selectedItem"
      :items="typeOptions"
      dense
      hide-details
      solo
      flat
      item-text="label"
      item-value="value"
      class="d-inline"
      :disabled="disabled"
      dark
      background-color="secondary"
      :menu-props="{ bottom: true, offsetY: true }"
      @change="changeType()"
    >
      <template #selection="{ item }">
        <span class="caption">{{ item.label }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "KpiTypePicker",
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      selectedItem: "structures",
      typeOptions: [
        { value: "structures", label: this.$t("kpi.type_options.structures") },
        {
          value: "substructures",
          label: this.$t("kpi.type_options.substructures"),
        },
        { value: "categories", label: this.$t("kpi.type_options.categories") },
        { value: "users", label: this.$t("kpi.type_options.users") },
      ],
    };
  },
  computed: {
    ...mapGetters("kpi", { type: "get_kpi_type" }),
  },
  beforeMount() {
    this.init();
  },
  methods: {
    ...mapMutations("kpi", ["set_kpi_type"]),
    init() {
      this.selectedItem = this.type ? this.type : this.typeOptions[0];
    },
    changeType() {
      this.set_kpi_type(
        this.typeOptions.filter((i) => i.value === this.selectedItem)[0]
      );
    },
  },
};
</script>

<style></style>
