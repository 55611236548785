var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticStyle:{"border-color":"rgba(0, 0, 0, 0.12) !important"},attrs:{"color":"grey lighten-3","outlined":"","dark":_vm.journeyPublished}},[_c('v-card-text',[_c('v-stepper',{staticClass:"transparent",attrs:{"non-linear":"","value":"3","alt-labels":"","flat":""},model:{value:(_vm.stepper),callback:function ($$v) {_vm.stepper=$$v},expression:"stepper"}},[_c('v-stepper-header',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"640","color":"info","disabled":_vm.contentAvailable},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-stepper-step',{attrs:{"complete":_vm.contentAvailable,"editable":!_vm.contentAvailable,"rules":[() => _vm.contentAvailable],"step":"1","color":"success darken-1","complete-icon":"mdi-check","error-icon":"mdi-alert-circle","edit-icon":"mdi-check"},on:{"click":function($event){!_vm.contentAvailable
                      ? _vm.$router.push({ name: 'JourneyBundles' })
                      : false}}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.contentAvailable ? _vm.$t("ng.content_added") : _vm.$t("gw.journey.steps.add_content.title")))])])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("gw.journey.steps.add_content.subtitle")))])]),_c('v-divider'),_c('v-tooltip',{attrs:{"bottom":"","max-width":"640","color":"info","disabled":_vm.contentValid},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-stepper-step',{attrs:{"complete":_vm.contentValid,"editable":!_vm.contentValid,"rules":[() => _vm.contentValid],"step":"1","color":"success darken-1","complete-icon":"mdi-check","error-icon":"mdi-alert-circle","edit-icon":"mdi-check"},on:{"click":function($event){!_vm.contentValid
                      ? _vm.$router.push({ name: 'JourneyBundles' })
                      : false}}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.contentValid ? _vm.$t("gw.journey.steps.valid_content.title_1") : _vm.$t("gw.journey.steps.valid_content.title_2")))])])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("gw.journey.steps.valid_content.subtitle")))])]),_c('v-divider'),_c('v-tooltip',{attrs:{"bottom":"","max-width":"640","color":"info","disabled":_vm.structuresSet},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-stepper-step',{attrs:{"complete":_vm.structuresSet,"editable":!_vm.structuresSet,"step":"3","color":"success darken-1","complete-icon":"mdi-check","error-icon":"mdi-close","edit-icon":"mdi-check"},on:{"click":function($event){!_vm.structuresSet
                      ? _vm.$router.push({ name: 'JourneyStructures' })
                      : false}}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.structuresSet ? _vm.$t("ng.structures_set") : _vm.$t("gw.journey.steps.visibility.title")))])])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("gw.journey.steps.visibility.subtitle")))])]),_c('v-divider'),_c('v-tooltip',{attrs:{"bottom":"","max-width":"640","color":"info","disabled":['active'].includes(_vm.journey.status) ||
              (['published'].includes(_vm.journey.status) && !!_vm.journey.start_at)},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-stepper-step',{attrs:{"complete":['active'].includes(_vm.journey.status) ||
                    (['published'].includes(_vm.journey.status) &&
                      !!_vm.journey.start_at),"editable":['unpublished'].includes(_vm.journey.status) ||
                    (['published'].includes(_vm.journey.status) &&
                      !_vm.journey.start_at),"step":"4","color":"success darken-1","complete-icon":"mdi-check","error-icon":"mdi-alert-circle","edit-icon":"mdi-check"},on:{"click":function($event){!['active', 'published'].includes(_vm.journey.status) &&
                    _vm.contentAvailable &&
                    _vm.contentValid &&
                    _vm.structuresSet
                      ? _vm.changeStatus('published')
                      : ['published'].includes(_vm.journey.status) &&
                        !_vm.journey.start_at
                      ? _vm.changeStatus('active')
                      : false}}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(["published"].includes(_vm.journey.status) && !_vm.journey.start_at ? _vm.$t("certificate.activation.activate") : ["published"].includes(_vm.journey.status) && !!_vm.journey.start_at ? _vm.$t("ng.starts_at_date", { date: new Date( _vm.journey.start_at ).toLocaleDateString(), }) : _vm.$t("gw.journey.steps.publish.title")))])])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("gw.journey.steps.publish.subtitle")))])])],1)],1)],1)],1),(_vm.showStatusDialog)?_c('JourneyDialogStatus',{attrs:{"dialog":_vm.showStatusDialog,"journey":_vm.journey,"new-status":_vm.newStatus},on:{"close":function($event){_vm.showStatusDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }